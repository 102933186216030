import customer_1 from 'assets/static/customers_logo/empresa1.png';
import customer_2 from 'assets/static/customers_logo/empresa2.png';
import customer_3 from 'assets/static/customers_logo/empresa3.png';
import customer_4 from 'assets/static/customers_logo/empresa4.png';
import customer_5 from 'assets/static/customers_logo/empresa5.png';

export const brands = [
  {
    image: customer_1,
    link: "https://youtube.com/",
  },
  {
    image: customer_2,
    link: "https://youtube.com/",
  },
  {
    image: customer_3,
    link: "https://youtube.com/",
  },
  {
    image: customer_4,
    link: "https://youtube.com/",
  },
  {
    image: customer_5,
    link: "https://youtube.com/",
  },
]