import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import VCDemo from "./VCDemo";
import { Box, Typography } from "@mui/material";
import landing_mobile from "assets/static/landing_mobile.png";

const Landing = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
      bg_color="var(--background-color1)"
      id="home"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          // height: "100vh",
          overflow: "hidden",

          justifyContent: "center",
          alignItems: "center",
        }}
        
      >
        <Typography
          sx={{
            color: "var(--text-color1)",
            fontWeight: "bold",
            fontSize: "2.8rem",
            textAlign: "center",
            marginBottom: "2vh",
          }}
        >
          Colaboração Remota Perfeita com sua Equipe
        </Typography>
        <Typography
          sx={{
            fontSize: "1.05rem",
            textAlign: "center",
            marginBottom: "6vh",
            maxWidth: "60rem",
            color: "var(--text-color2)",
          }}
        >
          O MeetES permite que vocês se conectem remotamente de forma fácil e
          eficiente, com videochamadas, chat e compartilhamento de tela, para
          trabalharem juntos além das fronteiras.
        </Typography>

        <VCDemo />

        <Box
          component={"img"}
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
            objectFit: "contain",
            width: "100%",
            height: "auto",
            filter: "drop-shadow(0px 18px 20px rgba(17, 12, 46, 0.4))",
          }}
          src={landing_mobile}
        />
      </Container>
    </CustomSection>
  );
};

export default Landing;
