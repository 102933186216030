import { useState } from "react";
import logo from "assets/static/logo/main_colored.svg";
import SidebarHeader from "./SidebarHeader";
import AdminMenu from "./RoleView/AdminMenu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function SideBar() {
  const { user } = useSelector((store) => store.AppReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [shrunk, setShrunk] = useState(false);

  const handleShrunk = () => {
    setShrunk(!shrunk);
  };

  return (
    <div
      className="hidethis"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: shrunk ? "7.2rem" : "26rem",
        backgroundColor: "#FFF",
        borderRight: "1px solid #E0E0E0",
        paddingTop: "0.4rem",
        transition: "0.3s ease-in-out",
        zIndex: 2,
      }}
    >
      <SidebarHeader
        {...{
          shrunk,
          handleShrunk,
        }}
      />

      <hr
        style={{
          width: "100%",
          border: "1px solid #c4c4c4",
          margin: 0,
          marginTop: "0.6rem",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.6rem 0",
          width: "100%",
          gap: "0.4rem",
          overflowY: "auto",
          height: "100%",
        }}
      >
        
          <AdminMenu {...{ shrunk }} />
      </div>

      <hr
        style={{
          width: "100%",
          border: "1px solid #c4c4c4",
          margin: 0,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem 0",
          width: "100%",
          gap: "0.4rem",
          fontSize: "0.8rem",
          color: "#636363",
        }}
      >
        <img src={logo} alt="Logo" style={{ width: "6rem" }} />
        <span>
          Copyrights ©  {new Date().getFullYear()}
          {/* <span>
            /temp/{" "}
            <button
              onClick={() => {
                navigate("/");
                return dispatch(logout(""));
              }}
            >
              Logout
            </button>
          </span> */}
        </span>
      </div>
    </div>
  );
}
