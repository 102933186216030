const MessageContainer = ({
  text,
  image_url,
  avatar,
  username,
  datetime,
  isMine = false,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: isMine ? "flex-end" : "flex-start",
        alignItems: "flex-end",
        gap: "0.6rem",
      }}
    >
      {!isMine && (
        <img
          src={avatar}
          alt={`Avatar de ${username}`}
          style={{
            width: "2.5rem",
            height: "2.5rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: !isMine
            ? "var(--background-color1)"
            : "rgb(var(--accent-color0), 0.2)",
          padding: "0.8rem 1rem",
          borderRadius: "1rem",
          margin: isMine ? "0 0 0 1rem" : "0 1rem 0 0",
        }}
      >
        {!isMine && (
          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}
          >
            {username}
          </span>
        )}
        <div
          style={{
            lineHeight: "1.2rem",
            fontSize: "0.8rem",
          }}
        >
          {text}
        </div>
        {image_url && (
          <img
            src={image_url}
            alt={`Imagem enviada por ${username}`}
            style={{
              width: "100%",
              borderRadius: "0.4rem",
              marginTop: "0.6rem",
              objectFit: "contain",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MessageContainer;
