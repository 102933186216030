import { logout } from "components/actions/AppActions";
import { List } from "components/list/list";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteData, get } from "utilities/requests";


export function MeetsView() {
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showWarning, setWarning] = useState(false);

  const [pagination, setPagination] = useState({ page: 1, last_page: 1 });
  const [search, setSearch] = useState('');
  const [selectedId, setSelectedId] = useState('');

  const [meets, setMeets] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getList = useCallback((page = 1, search = '') => {
    setLoadingSearch(true);
    get('meet/list?page=' + page + '&search=' + search).then((resp) => {
      console.log(resp)

      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        // this.setState({redirect:true,path:'/'});
        setLoadingSearch(false);
        setLoadingScreen(false);
        dispatch(logout())

        return;
      }
      else {
        setLoadingSearch(false);
        setLoadingScreen(false);
        setMeets(resp.meets)
      }
      
    }).catch((e) => {
      setLoadingSearch(false);
      setLoadingScreen(false);
    })
  }, [])

  const deleteItem = useCallback((id) => {
    setLoadingSearch(true);
    deleteData('meet/delete/' + id).then((resp) => {
      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        // this.setState({redirect:true,path:'/'});
        setLoadingSearch(false);
        dispatch(logout())

        return;
      }
      else {
        getList();
      }
    }).catch((e) => {
      setLoadingSearch(false);
    })
  }, [])
  useEffect(() => {
    getList();

  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <List
        search_input_value={search}
        search_text="Pesquise pelo nome da reunião"
        onChangeSearchInput={(value) => {
          setSearch(value);
        }}
        onSearchButtonClick={() => {
          let pages = { ...pagination };
          pages.page = 1;
          setPagination(pages);
          getList(1, search);
        }}
        title={"Reuniões"}
        not_found_message={"Nenhuma reunião encontrada"}
        button_text="Adicionar reunião"
        button_link="/reunioes/create"
        deleting_message={
          'Ao deletar esta reunião, ela não estará mais disponível no sistema'
        }
        onDeleteCancel={() => {
          setWarning(false);
        }}
        onDeleteConfirm={() => {
          // dispatch(deleteItem());
          deleteItem(selectedId)
          setWarning(false);
        }}
        onDeleteActionClick={(id) => {
          setSelectedId(id);
          setWarning(true);
        }}
        onEditActionClick={(id) => {
          navigate("/reunioes/edit/" + id);
        }}
        onChangePagination={(value) => {
          let pages = { ...pagination };
          pages.page = value;
          setPagination(pages);
          getList(value, search);
        }}
        onManagerActionClick={(id) => {
          navigate("/reunioes/" + id);
        }}
        pronoun='a'

        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={meets.map((item) => {
          item.guests_list = '<div>'
          item.guests.map((item2, id2) => {
            if (id2 != 0) {
              item.guests_list += '<hr/>';
            }
            item.guests_list += '<p style="margin-bottom:0">' + item2.name + ' - ' + item2.email + '</p>';
          })
          item.guests_list += '</div>'
          console.log(item.guests_list)
          return item;

        })}
        pagination={{
          last_page: pagination?.last_page,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          {
            name: "date",
            type: "date",
            header: "Data/Hora da Reunião",
          },
          {
            name: "guests_list",
            type: "text",
            header: "Convidados",
          },

        ]}
      ></List>
    </div>
  );
}
