import { Box } from "@mui/system";
import { Container } from "@mui/material";
import LoadingDots from "components/LoadingDots";
import logo_colored from "assets/static/logo/main_colored.svg";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./index.modules.css";
import { get } from "utilities/requests";
import { URL } from "utilities/variables";
import CustomSection from "components/CustomSection";

const TermosUsoPage = () => {
  const [readyToRender, setReadyToRender] = useState(false);
  const [all_terms, setAllTerms] = useState({});

  const getto = async () => {
    const res = await get(`policy-and-terms`);
    setReadyToRender(true);
    setAllTerms(res);
  };

  useEffect(() => {
    getto().catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    <CustomSection
      style={{
        overflow: "hidden",
        position: "relative",
        minHeight: "auto",
      }}
    >
      {readyToRender ? (
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.8rem",
            margin: "auto",
            padding: "8rem 0",
            width: "100vw",
            animation: "show_section 1s",
          }}
        >
          <img
            src={logo_colored}
            alt="EnterScience logotipo"
            style={{
              width: "20rem",
              margin: "10rem auto 6rem auto",
            }}
          />

          <div
            style={{
              margin: "6rem 0",
              gap: "4rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {all_terms["terms"].map((term, index) => {
              const [title, text] = term.content.split("_{slice}_");
              return (
                <div key={index} className="text-row">
                  <h2>{title}</h2>
                  <Typography variant="p"
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </div>
              );
            })}

            {/* caso não exista texto para mostrar, uma outra mensagem será mostrada: */}
            {all_terms["terms"].length === 0 ? (
              <>
                <p
                  style={{
                    fontSize: "2rem",
                    color: "var(--purple)",
                    textAlign: "center",
                    margin: "6rem 0",
                    fontFamily: "var(--Raleway)",
                  }}
                >
                  Não há termos de uso.
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </Container>
      ) : (
        <div
          style={{
            backgroundColor: "rgb(240, 244, 247)",
            minWidth: "100vw",
            minHeight: "100vh",
          }}
        >
          <LoadingDots />
        </div>
      )}
    </CustomSection>
  );
};

export default TermosUsoPage;
