import { BrowserRouter } from "react-router-dom";
import RoutesContainer from "routes";
import 'bootstrap/dist/css/bootstrap.css';
import "assets/css/app.scss";

import "./responsive.css";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./theme.css";

let theme = createTheme();
theme = responsiveFontSizes(theme);
// with material ui theme, its possible to make text components responsive automatically

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {/* <BrowserRouter>
          <RoutesContainer />
        </BrowserRouter> */}
        <RoutesContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;
