import { Tooltip } from "@mui/material";
import { ArrowLineRight } from "@phosphor-icons/react";
import style from "./style.module.css";
import { useEffect, useRef } from "react";

export default function ParticipantsRoom({
  participants,
  show_participants,
  setShowParticipants,
  focused_participant,
  setFocusedParticipant,
  playVideo,
}) {
  return (
    <div
      className="Transition-1"
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color0)",
        borderRadius: "1rem",
        padding: "1rem",
        position: "absolute",
        top: "0",
        right: show_participants ? "0" : "-100vw",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          Participantes
        </span>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Esconder participantes
            </span>
          }
          placement="bottom"
          arrow
          onClick={() => setShowParticipants(false)}
        >
          <ArrowLineRight size={24} />
        </Tooltip>
      </div>

      {/* participants container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {Object.keys(participants).map((key, index) => (
          <ParticipantItem
            key={index}
            {...{
              index,
              participant: participants[key],
              playVideo,
            }}
            onClick={() => {
              if (focused_participant === null) {
                console.log(
                  new Map([
                    [participants[key].stream.socket_id, participants[key]],
                  ])
                );
                setFocusedParticipant({
                  [participants[key].stream.socket_id]: participants[key],
                });
              } else {
                setFocusedParticipant(null);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
}

function ParticipantItem({ participant, playVideo, onClick, index }) {
  // const remoteVideo = useRef();

  // useEffect(() => {
  //   playVideo(remoteVideo.current, participant.stream.stream)
  //     ?.then(() => {
  //       remoteVideo.current.volume = 1;
  //     })
  //     .catch((err) => {
  //       console.error("media ERROR:", err);
  //     });
  // }, []);

  return (
    <div className={style.participantContainer} onClick={onClick}>
      {/* <video
        ref={remoteVideo}
        autoPlay
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 2,
        }}
      ></video> */}

      <img
        src={"https://picsum.photos/200" + index}
        alt=""
        style={{ aspectRatio: "1/1", height: "3.6rem", borderRadius: "50%" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          {participant.stream.socket_id}
        </span>
        <span
          style={{
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* green circle */}
          <span
            style={{
              display: "inline-block",
              width: "0.8rem",
              height: "0.8rem",
              borderRadius: "50%",
              backgroundColor: "#23a55a",
              marginRight: "0.4rem",
            }}
          ></span>
          Disponível
        </span>
      </div>
    </div>
  );
}
