import { useSelector } from "react-redux"

export default function Dashboard() {
    const user = useSelector(store=>store.AppReducer.user);
    return <div>
        <div
            style={{
                // backgroundColor: "red",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                padding: "2rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "1rem",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
                        {new Date().getHours() >= 5 && new Date().getHours() < 12
                            ? "Bom dia"
                            : new Date().getHours() >= 12 && new Date().getHours() < 18
                                ? "Boa tarde"
                                : "Boa noite"}
                        , {user?.name}!
                    </h2>
                    <span
                        style={{
                            fontSize: "1rem",
                            color: "#6c757d",
                        }}
                    >
                        Seja bem-vindo(a) ao painel Dashboard.
                    </span>
                </div>
            </div>
        </div>
    </div>

}