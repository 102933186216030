import { useEffect, useState } from "react";
import ChatRoom from "../ChatRoom";
import ParticipantsRoom from "../ParticipantsRoom";

export default function RightContentWrapper({
  participants,
  focused_participant,
  setFocusedParticipant,
  show_chat,
  setShowChat,
  show_participants,
  setShowParticipants,
  playVideo,
}) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <ChatRoom
        {...{
          show_chat,
          setShowChat,
        }}
      />
      <ParticipantsRoom
        {...{
          participants,
          show_participants,
          setShowParticipants,
          focused_participant,
          setFocusedParticipant,
          playVideo,
        }}
      />
    </div>
  );
}
