import VideoAreaParticipantItem from "./ParticipantItem";
import { useEffect, useState } from "react";
import VideoAreaBottomControlls from "./BottomControlls";

export default function VideoArea({
  participants,
  focused_participant,
  setFocusedParticipant,
  playVideo,

  // streaming control
  localStream,
  localStreamScreen,
  enableVideoStream,
  handleEnableVideoStream,
  enableAudioStream,
  handleEnableAudioStream,
  // share screen control
  localScreen,
  isShareScreen,
  handleStartScreenShare,
  handleDisconnectScreenShare,

  handleDisconnect,

  // right content control
  show_chat,
  setShowChat,
  show_participants,
  setShowParticipants,
}) {
  const [force_row_direction, setForceRowDirection] = useState(false);

  useEffect(() => {
    // row direction with 2 participants because it's better to see
    if (Object.keys(participants).length === 2) {
      setForceRowDirection(true);
    } else {
      setForceRowDirection(false);
    }
  }, [participants]);

  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: force_row_direction ? "row" : "column",
          flexWrap: "wrap",
          gap: "1rem",
          overflow: "hidden",
        }}
      >
        {Object.keys(participants).map((key, index) => {
          let row_grid = 0;
          const participants_count = Object.keys(participants).length;

          if (participants_count % 2 === 0) {
            row_grid = participants_count;
          } else {
            row_grid = participants_count - 1;
          }

          return (
            <VideoAreaParticipantItem
              key={index}
              {...{
                row_grid,
                participant: participants[key],
                participants,
                focused_participant,
                setFocusedParticipant,
                playVideo,
                localScreen,
                localStreamScreen,
                isShareScreen,
                index,
                participants_count,
              }}
            />
          );
        })}
      </div>

      <VideoAreaBottomControlls
        {...{
          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
          isShareScreen,
          handleStartScreenShare,
          handleDisconnectScreenShare,
          handleDisconnect,

          // right content control
          show_chat,
          setShowChat,
          show_participants,
          setShowParticipants,
        }}
      />
    </div>
  );
}
