import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box } from "@mui/material";

const SurveyCard = () => {
  const sim = 20;
  const nao = 80;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1.2rem",
        gap: "0.6rem",
        backgroundColor: "rgba(var(--accent-color1), 0.15)",
        borderRadius: "1rem",
        color: "var(--text-color2)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "0.6rem",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >Preparados para a apresentação do Henrique amanhã?</span>
        <EditNoteRoundedIcon />
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "0.6rem",
          cursor: "pointer",
          padding: "0.6rem 0",
          position: "relative",
          transition: "all 0.1s ease",
          borderRadius: "0.6rem",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            padding: "0.6rem",
          },
          "&:active": {
            top: "0.2rem",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>Sim</h5>
          <h5>{sim}%</h5>
        </div>

        <div
          style={{
            height: "0.6rem",
            width: `100%`,
            backgroundColor: "var(--background-color0)",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "0.6rem",
              width: `${sim}%`,
              backgroundColor: "rgba(var(--accent-color1), 0.5)",
            }}
          ></div>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "0.6rem",
          cursor: "pointer",
          padding: "0.6rem 0",
          position: "relative",
          transition: "all 0.1s ease",
          borderRadius: "0.6rem",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            padding: "0.6rem",
          },
          "&:active": {
            top: "0.2rem",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>Não</h5>
          <h5>{nao}%</h5>
        </div>

        <div
          style={{
            height: "0.6rem",
            width: `100%`,
            backgroundColor: "var(--background-color0)",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "0.6rem",
              width: `${nao}%`,
              backgroundColor: "rgba(var(--accent-color1), 0.5)",
            }}
          ></div>
        </div>
      </Box>

      <Box
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "0.6rem",
          cursor: "pointer",
          padding: "0.6rem 0",
          transition: "all 0.1s ease",
          borderRadius: "0.6rem",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            padding: "0.6rem",
          },
          "&:active": {
            top: "0.2rem",
          },
        }}
      >
        <h5>Adicionar opção</h5>
        <AddRoundedIcon />
      </Box>
    </div>
  );
};

export default SurveyCard;
