// import Header from "../../component/views/Header";
import { Outlet } from "react-router-dom";
import SideBar from "components/SideBar";

export function HomeView() {
  return (
    <div className={`app`}
      style={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* <SideBar
        image={image}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={setToggled}
        handleCollapsedChange={setCollapsed}
      /> */}
      {/* <Header
        image={image}
        handleToggleSidebar={setToggled}
        handleCollapsedChange={setCollapsed}
        handleImageChange={setImage}
      /> */}

      <SideBar />
      <div className="content-page"
      style={{
        height: "100vh",
        overflowY: "auto",
      }}
      >
        <div
          className="content"
          style={{
            transition: "all 0.3s ",
            background: "#f8f8fa",
          }}
        >
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
