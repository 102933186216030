import { ArrowLineRight } from "@phosphor-icons/react";
import ChatRoomMessageItem from "./MessageContainer";
import { Box, Tooltip } from "@mui/material";
import TextareaAutosize from "react-textarea-autosize";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import React  from 'react';
import moment from "moment";
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
export default function ChatRoom({ show_chat, setShowChat, socket,sala,username,admin_id }) {
  
  const user = useSelector(store => store.AppReducer.user);
  const [message, setMessage] = useState('');

  const [messages, setMessages] = useState([]);
  const messagesRef = useRef();
  
  const textRef = useRef();
  const messages_view = useRef();

  const send_message = (message)=>{
    if(message.length>2){
      let id = uuidv4();
      messagesRef.current.push({
        id,
        sender:{name:username},
        message,
        visto:undefined,
        isMine:true,
        created_at:moment().format('YYYY-MM-DD HH:mm:ss')
      })
      console.log(messagesRef.current)
      setMessage('');
      setMessages([...messagesRef.current])
      socket.emit('message',{sender:{name:username},message,sala_id:sala.id,id,admin_id:admin_id})
    }
  }

  useEffect(() => {
    // console.log('student update')
    // setMessages(get_messages_from_student(id_candidato).filter((item) => item.warning != true))
  }, []);

  useEffect(() => {
    messages_view.current.scrollTop = messages_view.current.scrollHeight;
  }, [messages]);

  useEffect(()=>{
    messages_view.current.scrollTop = messages_view.current.scrollHeight;
  },[show_chat])

  const process_messages = (data)=>{
    if(data.event=='receive'){
      messagesRef.current = messagesRef.current.map((item)=>{
        if(item.id==data.id){
          item.visto=false;
        }
        return item;
      })
    }
    else if(data.admin_id!=admin_id){
      messagesRef.current.push({
        ...data,
        isMine:false,
        created_at:moment().format('YYYY-MM-DD HH:mm:ss')

      })
    }
      console.log(messagesRef.current)
      setMessages([...messagesRef.current])

  }

  useEffect(()=>{
    messagesRef.current=[];
    socket.on('message',process_messages);
    return ()=>{
      socket.off('message',process_messages);
    }
  },[])
  return (
    <div
      className="Transition-1"
      style={{
        height: "100%",
        width: "30rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color0)",
        borderRadius: "1rem 0 0 1rem",
        padding: "1rem",

        position: "absolute",
        top: "0",
        right: show_chat ? "0" : "-100vw",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          Chat
        </span>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Esconder chat
            </span>
          }
          placement="bottom"
          arrow
          onClick={() => setShowChat(false)}
        >
          <ArrowLineRight size={24} />
        </Tooltip>
      </div>

      {/* chat container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          overflowY: "auto",
        }}
        ref={messages_view}
      >
        {messages?.map((message, index) => (
          <ChatRoomMessageItem
            key={index}
            {...{
              ...message,
            }}
          />
        ))}
      </div>

      {/* input */}
      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0.8rem",
            borderRadius: "1rem",

            backgroundColor: "var(--background-color2)",
            height: "max-content",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(var(--accent-color1), 0.3)",
            },
          }}
        >
          <AttachFileOutlinedIcon
            sx={{
              color: "rgb(255, 255, 255)",
            }}
          />
        </Box> */}
        <input
          ref={textRef}
          value={message}
          onKeyPress={(e) => {
            let keyCode = e.keyCode || e.which;
            if (keyCode == 13) {
              if (send_message(message)) {
                setMessage('');
                setTimeout(() => {
                  messages_view.current.scrollTop = messages_view.current.scrollHeight;
                }, 100);
              }
            }
          }}
          onChange={(e)=>{
            setMessage(e.target.value);
          }}
          placeholder="Digite sua mensagem aqui..."
          style={{
            width: "100%",
            padding: "1rem",
            fontSize: "0.9rem",
            borderRadius: "1rem",
            border: "none",
            outline: "none",
            resize: "none",
            fontFamily: "inherit",
            backgroundColor: "var(--background-color1)",
            color: "var(--text-color0)",
          }}

        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0.8rem",
            borderRadius: "1rem",
            backgroundColor: "rgba(var(--accent-color0), 0.4)",
            height: "max-content",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(var(--accent-color0), 0.5)",
            },
          }}
          onClick={()=>{
            if (send_message(message)) {
              setMessage('');
              setTimeout(() => {
                messages_view.current.scrollTop = messages_view.current.scrollHeight;
              }, 100);
            }
          }}
        >
          <SendRoundedIcon
            sx={{
              color: "rgb(255, 255, 255)",
            }}
          />
        </Box>
      </div>
    </div>
  );
}
