import { MicrophoneSlash } from "@phosphor-icons/react";
import style from "./style.module.css";
import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import React  from 'react';

export default function VideoAreaParticipantItem({
  row_grid,
  participant,
  participants,
  participants_count,
  focused_participant,
  setFocusedParticipant,
  playVideo,
  localScreen,
  isShareScreen,
  getAdminId,
  index,
  type
}) {
  const remoteVideo = useRef();
  const remoteScreen = useRef();
  const [isRemoteScreen, setIsRemoteScreen] = useState(false);
  console.log('name',participant)
  console.log('type',type)
  if (row_grid === 2) {
    row_grid = 4;
  } else if (row_grid > 10) {
    row_grid = 10;
  }
  let flexBasis = 100 / (row_grid / 2) - 1 + "%";

  console.log("row_grid", row_grid);
  console.log('participant',participant)

  useEffect(() => {
    console.log('updated_participant')
    console.log(participant);
    console.log(remoteVideo.current)
    console.log(participant.id,getAdminId())
    if(participant.stream!=null && (participant.video_track_cam!=null || participant.video_track_screen!=null) && (participant.video_cam==true || participant.screen==true)){
      let volume=participant.id!=getAdminId()?1:0;
      let stream = new MediaStream();
      console.log('PLAY video')
      console.log(participant)
      if(participant.screen==true && type=='screen'){
        stream.addTrack(participant.stream.getVideoTracks()[1]);
      }
      else{
        stream.addTrack(participant.stream.getVideoTracks()[0]);
      }
      playVideo(remoteVideo.current, stream)
        .then(() => {
          remoteVideo.current.volume = volume;
        })
        .catch((err) => {
          console.error("media ERROR2:", err);
        });
    }
    else{
      
    }
  }, [participant.video_track_screen,participant.screen,participant.video_track_cam,participant.video_cam,participant.video_show]);

  useEffect(() => {
    // if (participant.share_screen !== undefined) {
    //   setIsRemoteScreen(true);
    //   // setFocusedParticipant(participant);
    //   playVideo(remoteScreen.current, participant.share_screen.stream,participant.stream,participant.id!=getAdminId()?1:0)
    //     .then(() => {
    //       remoteScreen.current.volume = 1;
    //     })
    //     .catch((err) => {
    //       console.error("media ERROR1:", err);
    //     });
    // } else {
    //   setIsRemoteScreen(false);
    // }
  }, [participants]);

  return (
    <div
      className={style.participantContainer}
      style={focused_participant!=null && focused_participant['type']!=type?{
        position:'absolute',
        top:10,
        left:10,
        width:'20%',
        height:'20%',
        zIndex:3
      } :{
        // flexBasis: flexBasis,
      }}
      onClick={() => {
        if (focused_participant === null 
          // && participants_count > 1
        ) {
          console.log(new Map([[participant.id, participant]]));
          setFocusedParticipant({
            [participant.id]: participant,
            type
          });
        } else {
          setFocusedParticipant(null);
        }
      }}
    >
      {/* name and picture */}
      <div
        style={{
          display: isRemoteScreen ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex:3,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div 
          hidden={participant.video_cam==true || participant.screen==true}
          style={{
            width: "120px",
            height: "120px",
            borderRadius: "50%",
            objectFit: "cover",
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            background:participant.id==getAdminId()?'orange': 'purple'
          }}>
          <p style={{marginBottom:0,textAlign:'center',color:'white',fontSize:40}}>{participant?.name?.toUpperCase().substring(0,2)}</p>
        </div>
          {/* <img
            src={"https://picsum.photos/200" + index}
            alt="user"
            style={{
              width: "50%",
              maxWidth: "15rem",
              objectFit: "contain",
              aspectRatio: "1/1",
              borderRadius: "50%",
            }}
          /> */}
        </div>
        {(focused_participant==null || focused_participant['type']==type) && <span
          style={(participant.screen==true && type=='screen') ?{
            backgroundColor: "rgba(var(--accent-color0), 0.5)",
            color: "white",
            fontWeight: "bold",
            padding: "0.2rem 0.5rem",
            borderRadius: "0.5rem",
            position:'absolute',
            top:10,
            right:10
          }:{
            backgroundColor: "rgba(var(--accent-color0), 0.5)",
            color: "white",
            fontWeight: "bold",
            padding: "0.2rem 0.5rem",
            borderRadius: "0.5rem",
            position:'absolute',
            bottom:10
          }}
        >
          {participant.name}
        </span>}
      </div>
      {/* media source */}
      <div className={style.mediaSource}>
        {<video
          id={'video_id_'+participant.id}
          hidden={participant.video_cam==false && participant.screen==false}
          ref={remoteVideo}
          autoPlay
          style={{
            objectFit:'contain',
            display: isRemoteScreen ? "none" : "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        ></video>}
        <video
          ref={remoteScreen}
          autoPlay
          hidden={participant.video_cam==false && participant.screen==false}

          style={{
            objectFit:'contain',

            display: isRemoteScreen ? "block" : "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            objectFit: "contain",
            zIndex: 1,
          }}
        ></video>
        <video
          ref={localScreen}
          autoPlay
          style={{
            display: isRemoteScreen ? "none" : "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            objectFit: "contain",
            zIndex: 1,
          }}
        ></video>
      </div>

      {/* config overlay */}
      <div
        style={{
          display: "flex",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          {/* {participant.is_muted && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  O participante desativou o microfone
                </span>
              }
              placement="bottom"
              arrow
            >
              <MicrophoneSlash
                weight="fill"
                style={{
                  color: "rgba(var(--accent-color1), 0.6)",
                  fontSize: "1.8rem",
                  position: "absolute",
                  top: "0.8rem",
                  right: "0.6rem",
                }}
              />
            </Tooltip>
          )} */}
        </div>
      </div>
    </div>
  );
}
