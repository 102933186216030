import { useEffect, useState } from "react";
// unidades
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowGoBackLine } from "react-icons/ri";
import moment from "moment";
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import { TextReadMore } from "components/TextReadMore";
import { Grid } from "components/list/grid";
import { useDispatch } from "react-redux";
import { get } from "utilities/requests";
import { logout } from "components/actions/AppActions";
import DefaultButton from "components/DefaultButton/DefaultButton";


export function MeetsManagerView() {
  const [ search,setSearch ] = useState('')
  const [ loadingScreen,setLoadingScreen ] = useState(true)
  const [ meet,setMeet ] = useState({})




  const dispatch = useDispatch();
  const history=useNavigate();
  const params = useParams();

  useEffect(() => {
    get('meet/get/' + params.id).then((resp) => {
      console.log(resp)

      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        // this.setState({redirect:true,path:'/'});
        setLoadingScreen(false);
        dispatch(logout())

        return;
      }
      else {
        setMeet(resp.meet)
        setLoadingScreen(false);
      }

    }).catch((e) => {
      setLoadingScreen(false);
    })
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div className="card">
        <div className="card-body">
         

          {loadingScreen==false && <div>
            <div style={{ float: "right" }}>
              <DefaultButton
                bg="secondary"
                text="Voltar"
                icon={<RiArrowGoBackLine />}
                to={`/reunioes`}
              />
            </div>
            <div className="flex jcsb">
              <h4 className="header">{meet?.name}</h4>
            </div>
            <TextReadMore
              title="Local: "
              element="div"
              limit={1000}
              style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
            >
              {meet?.local ?? '-'}
            </TextReadMore>
            <div style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}><b style={{float: 'left', marginRight: '2px'}}>Link:</b> <a href={meet?.link} target="_blank">{meet?.link}</a></div>

            <TextReadMore
              title="Convidados: "
              element="div"
              limit={1000}
              style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
            >
              {''}
            </TextReadMore>
           
              <Grid
                onChangeSearchInput={(value) => {
                  setSearch(value);
                }}
                search_input_value={search}
                loading_search={false}
                fields={[
                  { name: "name", type: "text", header: "Nome" },
                  { name: "email", type: "text", header: "E-mail" },
                  { name: "view_invite_message", type: "text", header: "Visualizou convite" },
                  { name: "status_message", type: "text", header: "Status" },
                ]}
                items={meet?.guests?.filter((item)=>item.name.toLowerCase().includes(search.toLowerCase())).map((item)=>{
                  item.view_invite_message = item.view_invite ==true?'<span style="color:green">Sim</span>':'<span style="color:red">Não</span>'
                  item.status_message = item.status =='Y'?'<span style="color:green">Sim</span>':(item.status=='N'?'<span style="color:red">Não</span>':(item.status=='M'?'<span style="color:darkgoldenrod">Talvez</span>':'<span style="color:darkgoldenrod">Não respondeu</span>'))
                  return item
                })}
                hideManager
                hideEdit
                hideDelete
                not_found_message={"Nenhum convidado encontrado."}
                loading_screen={loadingScreen}
                button_text={"Editar reunião"}
                button_link={`/reunioes/edit/${params.id}`}
                search_text={"Pesquisar pelo nome do convidado"}
                pagination={{
                  last_page: 1,
                  current_page: 1,
                }}
                onChangePagination={function (page) {
                }}
                top_text={''}
                onSearchButtonClick={function () {
                }}
              />
            
          </div>}
          {loadingScreen==true && <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:50}}><CircularProgress/></div>}

        </div>
      </div>
    </div>
  );
}
