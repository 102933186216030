import { Box } from "@mui/material";

const MVPItem = ({ title, description, icon, align_left = true }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        gap: "2rem",
        alignSelf: align_left ? "flex-start" : "flex-end",
        flexDirection: {
          xs: "column",
          sm: align_left ? "row" : "row-reverse",
        },
        maxWidth: {
          xs: "100%",
          lg: "90%",
        },
        background: "linear-gradient(180deg, var(--background-color1) 0%, var(--background-color0) 100%)",
        padding: "2rem",
        borderRadius: "1rem",
        alignItems: {
          xs: "center",
          sm: "inherit",
        },
      }}
    >
      <Box
        component="img"
        src={icon}
        alt="MVP Icon"
        sx={{
          width: {
            xs: "10rem",
            sm: "18rem",
          },

          top: {
            xs: "auto",
            sm: "50%",
          },
          transform: {
            xs: "none",
            sm: "translateY(-50%)",
          },

          objectFit: "contain",

          position: {
            xs: "relative",
            sm: "absolute",
          }
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          textAlign: {
            xs: "center",
            sm: align_left ? "left" : "right",
          },
          padding: {
            xs: "0",
            sm: align_left ? "0 0 0 19.5rem" : "0 19.5rem 0 0",
          },
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.3rem",
          }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: "1rem",
            textAlign: "justify",
          }}
        >
          {description}
        </span>
      </Box>
    </Box>
  );
};

export default MVPItem;
