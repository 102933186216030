import { Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import FAQItem from "./FAQItem";
import faq_bg from "assets/static/faq_bg.jpg";

const FAQPage = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        position: "relative",
        gap: "4rem",
        padding: "6rem 0",
        height: "max-content",
        minHeight: "max-content",
      }}
      bg_color="var(--background-color0)"
      id="home"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100%",
          overflow: "hidden",

          maskImage: "linear-gradient(to top, transparent 0%, black 50%)",
          WebkitMaskImage: "linear-gradient(to top, transparent 0%, black 50%)",
        }}
      >
        <img
          src={faq_bg}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: 0.4,
          }}
          alt="FAQ plano de fundo"
        />
      </div>

      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "max-content",
          gap: "2rem",
          zIndex: 1,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Perguntas Frequentes
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            marginBottom: "4rem",
            fontWeight: "normal",
            maxWidth: "30rem",
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "40px",
              borderTop: "2px solid #444",
              margin: "30px auto 0",
            },
          }}
        >
          Confira as perguntas mais frequentes sobre o MeetES.
        </Typography>

        <FAQItem
          index={0}
          question="Como faço para iniciar uma videochamada no site?"
          answer={
            'Para iniciar uma videochamada, basta acessar o site e clicar no botão "Começar". Você será redirecionado(a) para uma sala de videochamada, onde poderá convidar outros participantes por meio de um link compartilhável.'
          }
        />
        <FAQItem
          index={1}
          question="Posso compartilhar minha tela durante uma videochamada?"
          answer="Sim, você pode compartilhar sua tela durante uma videochamada. Durante a chamada, basta procurar o ícone de compartilhamento de tela na barra de ferramentas e selecionar a janela ou aplicativo que deseja compartilhar."
        />
        <FAQItem
          index={2}
          question="É possível agendar uma videochamada com antecedência?"
          answer="Sim, o nosso site oferece a opção de agendar videochamadas com antecedência. Você pode selecionar uma data e horário específicos para a chamada e convidar os participantes com antecedência. Ao agendar a videochamada, os participantes receberão um convite por e-mail com o link e as informações necessárias para se juntarem à chamada no horário agendado."
        />
        <FAQItem
          index={3}
          question="Posso utilizar o chat por texto sem participar de uma videochamada?"
          answer="Sim, você pode utilizar o recurso de chat por texto separadamente, sem a necessidade de participar de uma videochamada. Basta acessar a seção de chat no site e começar a enviar mensagens instantâneas para outros usuários."
        />
        <FAQItem
          index={4}
          question="Como posso ter certeza de que minhas videochamadas são seguras e privadas?"
          answer="A segurança e a privacidade são prioridades em nosso site. Implementamos criptografia de ponta a ponta para proteger suas chamadas e adotamos medidas rigorosas para manter seus dados seguros. Além disso, não armazenamos informações confidenciais após o término das chamadas."
        />
      </Container>
    </CustomSection>
  );
};

export default FAQPage;
