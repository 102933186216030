import { MicrophoneSlash } from "@phosphor-icons/react";
import style from "./style.module.css";
import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function VideoAreaParticipantItem({
  row_grid,
  participant,
  participants,
  participants_count,
  focused_participant,
  setFocusedParticipant,
  playVideo,
  localScreen,
  isShareScreen,
  index,
}) {
  const remoteVideo = useRef();
  const remoteScreen = useRef();
  const [isRemoteScreen, setIsRemoteScreen] = useState(false);

  if (row_grid === 2) {
    row_grid = 4;
  } else if (row_grid > 10) {
    row_grid = 10;
  }
  let flexBasis = 100 / (row_grid / 2) - 1 + "%";

  console.log("row_grid", row_grid);

  useEffect(() => {
    console.log(participant);
    playVideo(remoteVideo.current, participant.stream.stream)
      ?.then(() => {
        remoteVideo.current.volume = 1;
      })
      .catch((err) => {
        console.error("media ERROR:", err);
      });
  }, []);

  useEffect(() => {
    if (participant.share_screen !== undefined) {
      setIsRemoteScreen(true);
      // setFocusedParticipant(participant);
      playVideo(remoteScreen.current, participant.share_screen.stream)
        ?.then(() => {
          remoteScreen.current.volume = 1;
        })
        .catch((err) => {
          console.error("media ERROR:", err);
        });
    } else {
      setIsRemoteScreen(false);
    }
  }, [participants]);

  return (
    <div
      className={style.participantContainer}
      style={{
        flexBasis: flexBasis,
      }}
      onClick={() => {
        if (focused_participant === null && participants_count > 1) {
          console.log(new Map([[participant.stream.socket_id, participant]]));
          setFocusedParticipant({
            [participant.stream.socket_id]: participant,
          });
        } else {
          setFocusedParticipant(null);
        }
      }}
    >
      {/* name and picture */}
      <div
        style={{
          display: isRemoteScreen ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 3,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={"https://picsum.photos/200" + index}
            alt="user"
            style={{
              width: "50%",
              maxWidth: "15rem",
              objectFit: "contain",
              aspectRatio: "1/1",
              borderRadius: "50%",
            }}
          />
        </div>
        <span
          style={{
            backgroundColor: "rgba(var(--accent-color0), 0.5)",
            color: "white",
            fontWeight: "bold",
            padding: "0.2rem 0.5rem",
            borderRadius: "0.5rem",
          }}
        >
          {participant.stream.socket_id}
        </span>
      </div>
      {/* media source */}
      <div className={style.mediaSource}>
        <video
          ref={remoteVideo}
          autoPlay
          style={{
            display: isRemoteScreen ? "none" : "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 2,
          }}
        ></video>
        <video
          ref={remoteScreen}
          autoPlay
          style={{
            display: isRemoteScreen ? "block" : "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            objectFit: "contain",
            zIndex: 1,
          }}
        ></video>
        <video
          ref={localScreen}
          autoPlay
          style={{
            display: isRemoteScreen ? "none" : "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            objectFit: "contain",
            zIndex: 1,
          }}
        ></video>
      </div>

      {/* config overlay */}
      <div
        style={{
          display: "flex",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          {/* {participant.is_muted && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  O participante desativou o microfone
                </span>
              }
              placement="bottom"
              arrow
            >
              <MicrophoneSlash
                weight="fill"
                style={{
                  color: "rgba(var(--accent-color1), 0.6)",
                  fontSize: "1.8rem",
                  position: "absolute",
                  top: "0.8rem",
                  right: "0.6rem",
                }}
              />
            </Tooltip>
          )} */}
        </div>
      </div>
    </div>
  );
}
