import React, { useEffect } from "react";
import ChatRoom from "./ChatRoom";
import VideoArea from "./VideoArea";
import { Device } from "mediasoup-client";
import { io as socketIOClient } from "socket.io-client";
import { config } from "app.config";
import UserInfoForm from "./UserInfoForm";
import { useNavigate } from "react-router-dom";
import RightContentWrapper from "./RightContentWrapper";
import swal from "sweetalert";

const MODE_STREAM = "stream";
const MODE_SHARE_SCREEN = "share_screen";

export default function MeetingRoom() {
  const [focused_participant, setFocusedParticipant] = React.useState(null);
  const navigate = useNavigate();
  let userSocket = null;

  const localScreen = React.useRef();
  const localStreamScreen = React.useRef();

  const [show_chat, setShowChat] = React.useState(false);
  const [show_participants, setShowParticipants] = React.useState(true);

  const localVideo = React.useRef();
  const localStream = React.useRef();
  const clientId = React.useRef();
  const device = React.useRef();
  const producerTransport = React.useRef();
  const videoProducer = React.useRef({});
  const audioProducer = React.useRef({});
  const consumerTransport = React.useRef();
  const videoConsumers = React.useRef({});
  const audioConsumers = React.useRef({});
  const consumersStream = React.useRef({});
  const socketRef = React.useRef();

  const [useVideo, setUseVideo] = React.useState(true);
  const [useAudio, setUseAudio] = React.useState(true);
  const [isStartMedia, setIsStartMedia] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);
  const [remoteVideos, setRemoteVideos] = React.useState({});
  const [isShareScreen, setIsShareScreen] = React.useState(false);
  const [roomName, setRoomName] = React.useState("");
  const [username, setUsername] = React.useState("");

  // ============ STREAMING CONTROL START ==========
  const [enableVideoStream, setEnableVideoStream] = React.useState(useVideo);
  const [enableAudioStream, setEnableAudioStream] = React.useState(useAudio);

  const [enableVideoShare, setEnableVideoShare] = React.useState(useVideo);
  const [enableAudioShare, setEnableAudioShare] = React.useState(useAudio);

  const handleEnableVideoStream = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getVideoTracks()[0].enabled = cond;
    setEnableVideoStream(cond);
  };
  const handleEnableAudioStream = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getAudioTracks()[0].enabled = cond;
    setEnableAudioStream(cond);
  };
  const handleEnableVideoShare = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getVideoTracks()[0].enabled = cond;
    setEnableVideoShare(cond);
  };
  const handleEnableAudioShare = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getAudioTracks()[0].enabled = cond;
    setEnableAudioShare(cond);
  };

  // ============STREAMING CONTROL END ==========
  // ============ ROOM START ==========

  const handleRoomName = (e) => {
    setRoomName(e.target.value);
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };
  // ============ ROOM END ==========

  // ============ SHARE SCREEN START ==========
  const handleStartScreenShare = () => {
    if (localStreamScreen.current) {
      console.warn("WARN: local media ALREADY started");
      return;
    }

    const mediaDevices = navigator.mediaDevices;
    mediaDevices
      .getDisplayMedia({ audio: useAudio, video: useVideo })
      .then((stream) => {
        localStreamScreen.current = stream;

        playVideo(localScreen.current, localStreamScreen.current);
        handleConnectScreenShare();
        setIsShareScreen(true);
        const screenTrack = stream.getTracks()[0];
        screenTrack.onended = function () {
          handleDisconnectScreenShare();
        };
      })
      .catch((err) => {
        console.error("media ERROR:", err);
      });
  };

  async function handleConnectScreenShare() {
    if (!localStreamScreen.current) {
      console.warn("WARN: local media NOT READY");
      return;
    }

    // // --- connect socket.io ---
    // await connectSocket().catch((err: any) => {
    //     console.error(err);
    //     return;
    // });

    // console.log('connected');

    // --- get capabilities --
    const data = await sendRequest("getRouterRtpCapabilities", {});
    console.log("getRouterRtpCapabilities:", data);
    await loadDevice(data);

    // --- get transport info ---
    console.log("--- createProducerTransport --");
    const params = await sendRequest("createProducerTransport", {
      mode: MODE_SHARE_SCREEN,
    });
    console.log("transport params:", params);
    producerTransport.current = device.current.createSendTransport(params);
    console.log("createSendTransport:", producerTransport.current);

    // --- join & start publish --
    producerTransport.current.on(
      "connect",
      async ({ dtlsParameters }, callback, errback) => {
        console.log("--trasnport connect");
        sendRequest("connectProducerTransport", {
          dtlsParameters: dtlsParameters,
        })
          .then(callback)
          .catch(errback);
      }
    );

    producerTransport.current.on(
      "produce",
      async ({ kind, rtpParameters }, callback, errback) => {
        console.log("--trasnport produce");
        try {
          const { id } = await sendRequest("produce", {
            transportId: producerTransport.current.id,
            kind,
            rtpParameters,
            mode: MODE_SHARE_SCREEN,
          });
          callback({ id });
          console.log("--produce requested, then subscribe ---");
          subscribe();
        } catch (err) {
          errback(err);
        }
      }
    );

    producerTransport.current.on("connectionstatechange", (state) => {
      switch (state) {
        case "connecting":
          console.log("publishing...");
          break;

        case "connected":
          console.log("published");
          //  setIsConnected(true);
          break;

        case "failed":
          console.log("failed");
          producerTransport.current.close();
          break;

        default:
          break;
      }
    });

    if (useVideo) {
      const videoTrack = localStreamScreen.current.getVideoTracks()[0];
      if (videoTrack) {
        const trackParams = { track: videoTrack };
        videoProducer.current[MODE_SHARE_SCREEN] =
          await producerTransport.current.produce(trackParams);
      }
    }
    if (useAudio) {
      const audioTrack = localStreamScreen.current.getAudioTracks()[0];
      if (audioTrack) {
        const trackParams = { track: audioTrack };
        audioProducer.current[MODE_SHARE_SCREEN] =
          await producerTransport.current.produce(trackParams);
      }
    }
  }

  function handleStopScreenShare() {
    if (localStreamScreen.current) {
      pauseVideo(localScreen.current);
      stopLocalStream(localStreamScreen.current);
      localStreamScreen.current = null;
      setIsShareScreen(false);
    }
  }
  async function handleDisconnectScreenShare() {
    handleStopScreenShare();
    {
      const producer = videoProducer.current[MODE_SHARE_SCREEN];
      producer?.close();
      delete videoProducer.current[MODE_SHARE_SCREEN];
    }
    {
      const producer = audioProducer.current[MODE_SHARE_SCREEN];
      producer?.close();
      delete audioProducer.current[MODE_SHARE_SCREEN];
    }

    await sendRequest("producerStopShareScreen", {});
  }

  // ============ SHARE SCREEN END ==========

  // ============ MEDIA START ==========
  const handleStartMedia = () => {
    if (localStream.current) {
      console.warn("WARN: local media ALREADY started");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: useAudio, video: useVideo })
      .then((stream) => {
        localStream.current = stream;
        playVideo(localVideo.current, localStream.current);
        setIsStartMedia(true);
      })
      .catch((err) => {
        console.error("media ERROR:", err);
      });
  };

  function handleStopMedia() {
    if (localStream.current) {
      pauseVideo(localVideo.current);
      stopLocalStream(localStream.current);
      localStream.current = null;
      setIsStartMedia(false);
    }
  }

  function handleDisconnect() {
    handleStopMedia();
    handleStopScreenShare();
    // if (videoProducer.current) {
    //     videoProducer.current.close(); // localStream will stop
    //     videoProducer.current = null;
    // }
    {
      for (const mode in videoProducer.current) {
        const producer = videoProducer.current[mode];
        producer?.close();
        delete videoProducer.current[mode];
      }
    }
    {
      for (const mode in audioProducer.current) {
        const producer = audioProducer.current[mode];
        producer?.close();
        delete audioProducer.current[mode];
      }
    }
    // if (audioProducer.current) {
    //     audioProducer.current.close(); // localStream will stop
    //     audioProducer.current = null;
    // }
    if (producerTransport.current) {
      producerTransport.current.close(); // localStream will stop
      producerTransport.current = null;
    }

    for (const key in videoConsumers.current) {
      for (const key2 in videoConsumers.current[key]) {
        const consumer = videoConsumers.current[key][key2];
        consumer.close();
        delete videoConsumers.current[key][key2];
      }
    }
    for (const key in audioConsumers.current) {
      for (const key2 in audioConsumers.current[key]) {
        const consumer = audioConsumers.current[key][key2];
        consumer.close();
        delete audioConsumers.current[key][key2];
      }
    }

    if (consumersStream.current) {
      consumersStream.current = {};
    }

    if (consumerTransport.current) {
      consumerTransport.current.close();
      consumerTransport.current = null;
    }

    removeAllRemoteVideo();

    disconnectSocket();
    setIsConnected(false);
    navigate("/");
  }

  function playVideo(element, stream) {
    if (element.srcObject) {
      console.warn("element ALREADY playing, so ignore");
      return;
    }
    element.srcObject = stream;
    element.volume = 0;
    return element.play();
  }

  function pauseVideo(element) {
    try {
      element.pause();
      element.srcObject = null;
    } catch (err) {
      console.error("pauseVideo ERROR:", err);
    }
  }

  function stopLocalStream(stream) {
    let tracks = stream.getTracks();
    if (!tracks) {
      console.warn("NO tracks");
      return;
    }

    tracks.forEach((track) => track.stop());
  }

  function addRemoteTrack(id, track, mode) {
    // let video: any = findRemoteVideo(id);
    // if (!video) {
    //     video = addRemoteVideo(id);
    //     video.controls = '1';
    // }

    // if (video.srcObject) {
    //     video.srcObject.addTrack(track);
    //     return;
    // }

    if (id === clientId.current) {
      return false;
    }

    console.log("addremotetrack");
    console.log(track);

    if (consumersStream.current[id] == undefined) {
      consumersStream.current[id] = {};
    }

    if (consumersStream.current[id][mode] == undefined) {
      const newStream = new MediaStream();
      newStream.addTrack(track);
      consumersStream.current[id][mode] = {
        stream: newStream,
        socket_id: id,
      };
    } else {
      //add audiotrack
      consumersStream.current[id][mode].stream.addTrack(track);
    }

    // setRemoteVideos((peers) => {
    //   const newPeers = peers;

    //   return { ...consumersStream.current };
    // });
    setRemoteVideos({ ...consumersStream.current });

    // setRemoteVideos((peers: any) => {
    //     const newPeers: any = peers;
    //     if (newPeers[id] == undefined) {
    //         newPeers[id] = {};
    //     }
    //     newPeers[id][mode] = {
    //         stream: newStream,
    //         socket_id: id,
    //     };
    //     return { ...peers, ...newPeers };
    // });
    // setShouldLoadConsumerShareScreen

    // playVideo(video, newStream)
    //     .then(() => {
    //         video.volume = 1.0;
    //     })
    //     .catch((err: any) => {
    //         console.error('media ERROR:', err);
    //     });
  }

  // function addRemoteVideo(id) {
  //   let existElement = findRemoteVideo(id);
  //   if (existElement) {
  //     console.warn("remoteVideo element ALREADY exist for id=" + id);
  //     return existElement;
  //   }

  //   let element = document.createElement("video");
  //   return element;
  // }

  // function findRemoteVideo(id) {
  //   let element = remoteVideos.current[id];
  //   return element;
  // }

  // function removeRemoteVideoByMode(id: any, mode: string) {
  //     console.log(' ---- removeRemoteVideo() id=' + id);
  //     delete consumersStream.current[id][mode];
  //     setRemoteVideos((peers: any) => {
  //         const newPeers: any = peers;
  //         delete newPeers[id][mode];

  //         return { ...peers, ...newPeers };
  //     });
  // }

  function removeRemoteVideo(id, mode) {
    console.log(" ---- removeRemoteVideo() id=" + id);
    if (mode == MODE_STREAM) {
      delete consumersStream.current[id];
    } else {
      delete consumersStream.current[id][mode];
    }

    setRemoteVideos((peers) => {
      const newPeers = peers;
      delete newPeers[id];

      return { ...consumersStream.current };
    });
    // if (element) {
    //     element.pause();
    //     element.srcObject = null;
    //     remoteContainer.removeChild(element);
    // } else {
    //     console.log('child element NOT FOUND');
    // }
  }

  function removeAllRemoteVideo() {
    console.log(" ---- removeAllRemoteVideo() id");
    consumersStream.current = {};
    setRemoteVideos((peers) => {
      const newPeers = {};

      return { ...newPeers };
    });
    // while (remoteContainer.firstChild) {
    //     remoteContainer.firstChild.pause();
    //     remoteContainer.firstChild.srcObject = null;
    //     remoteContainer.removeChild(remoteContainer.firstChild);
    // }
  }

  async function consumeAdd(
    transport,
    remoteSocketId,
    prdId,
    trackKind,
    mode = MODE_STREAM
  ) {
    console.log("--start of consumeAdd -- kind=%s", trackKind);
    const { rtpCapabilities } = device.current;
    //const data = await socket.request('consume', { rtpCapabilities });
    const data = await sendRequest("consumeAdd", {
      rtpCapabilities: rtpCapabilities,
      remoteId: remoteSocketId,
      kind: trackKind,
      mode: mode,
    }).catch((err) => {
      console.error("consumeAdd ERROR:", err);
    });
    const { producerId, id, kind, rtpParameters } = data;
    if (prdId && prdId !== producerId) {
      console.warn("producerID NOT MATCH");
    }

    let codecOptions = {};
    const consumer = await transport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
      mode,
    });
    //const stream = new MediaStream();
    //stream.addTrack(consumer.track);

    addConsumer(remoteSocketId, consumer, kind, mode);
    consumer.remoteId = remoteSocketId;
    consumer.on("transportclose", () => {
      console.log("--consumer transport closed. remoteId=" + consumer.remoteId);
      //consumer.close();
      //removeConsumer(remoteId);
      //removeRemoteVideo(consumer.remoteId);
    });
    consumer.on("producerclose", () => {
      console.log("--consumer producer closed. remoteId=" + consumer.remoteId);
      consumer.close();
      removeConsumer(consumer.remoteId, kind, mode);
      removeRemoteVideo(consumer.remoteId, mode);
    });
    consumer.on("trackended", () => {
      console.log("--consumer trackended. remoteId=" + consumer.remoteId);
      //consumer.close();
      //removeConsumer(remoteId);
      //removeRemoteVideo(consumer.remoteId);
    });

    console.log("--end of consumeAdd");
    //return stream;

    if (kind === "video") {
      console.log("--try resumeAdd --");
      sendRequest("resumeAdd", {
        remoteId: remoteSocketId,
        kind: kind,
        mode,
      })
        .then(() => {
          console.log("resumeAdd OK");
        })
        .catch((err) => {
          console.error("resumeAdd ERROR:", err);
        });
    }
    return new Promise((resolve, reject) => {
      addRemoteTrack(remoteSocketId, consumer.track, mode);
      resolve();
    });
  }

  async function handleConnect() {
    if (!localStream.current) {
      console.warn("WARN: local media NOT READY");
      return;
    }

    if (username === "") {
      console.warn("WARN: empty username");
      swal("Atenção!", "Por favor insira um nome de usuário.", "warning");
      return;
    }

    // --- connect socket.io ---
    await connectSocket().catch((err) => {
      console.error(err);
      return;
    });

    console.log("connected");

    // --- get capabilities --
    const data = await sendRequest("getRouterRtpCapabilities", {});
    console.log("getRouterRtpCapabilities:", data);
    await loadDevice(data);

    // --- get transport info ---
    console.log("--- createProducerTransport --");
    const params = await sendRequest("createProducerTransport", {
      mode: MODE_STREAM,
    });
    console.log("transport params:", params);
    producerTransport.current = device.current.createSendTransport(params);
    console.log("createSendTransport:", producerTransport.current);

    // --- join & start publish --
    producerTransport.current.on(
      "connect",
      async ({ dtlsParameters }, callback, errback) => {
        console.log("--trasnport connect");
        sendRequest("connectProducerTransport", {
          dtlsParameters: dtlsParameters,
        })
          .then(callback)
          .catch(errback);
      }
    );

    producerTransport.current.on(
      "produce",
      async ({ kind, rtpParameters }, callback, errback) => {
        console.log("--trasnport produce");
        try {
          const { id } = await sendRequest("produce", {
            transportId: producerTransport.current.id,
            kind,
            rtpParameters,
            mode: MODE_STREAM,
            username,
          });
          callback({ id });
          console.log("--produce requested, then subscribe ---");
          subscribe();
        } catch (err) {
          errback(err);
        }
      }
    );

    producerTransport.current.on("connectionstatechange", (state) => {
      switch (state) {
        case "connecting":
          console.log("publishing...");
          break;

        case "connected":
          console.log("published");
          setIsConnected(true);
          break;

        case "failed":
          console.log("failed");
          producerTransport.current.close();
          break;

        default:
          break;
      }
    });

    if (useVideo) {
      const videoTrack = localStream.current.getVideoTracks()[0];
      if (videoTrack) {
        const trackParams = { track: videoTrack };
        videoProducer.current[MODE_STREAM] =
          await producerTransport.current.produce(trackParams);
      }
    }
    if (useAudio) {
      const audioTrack = localStream.current.getAudioTracks()[0];
      if (audioTrack) {
        const trackParams = { track: audioTrack };
        audioProducer.current[MODE_STREAM] =
          await producerTransport.current.produce(trackParams);
      }
    }
  }

  async function subscribe() {
    // console.log(socketRef.current);
    if (!socketRef.current) {
      await connectSocket().catch((err) => {
        console.error(err);
        return;
      });
    }

    // --- get capabilities --
    const data = await sendRequest("getRouterRtpCapabilities", {});
    console.log("getRouterRtpCapabilities:", data);
    await loadDevice(data);
    //  }

    // --- prepare transport ---
    console.log("--- createConsumerTransport --");
    if (!consumerTransport.current) {
      const params = await sendRequest("createConsumerTransport", {});
      console.log("transport params:", params);
      consumerTransport.current = device.current.createRecvTransport(params);
      console.log("createConsumerTransport:", consumerTransport.current);

      // --- join & start publish --
      consumerTransport.current.on(
        "connect",
        async ({ dtlsParameters }, callback, errback) => {
          console.log("--consumer trasnport connect");
          sendRequest("connectConsumerTransport", {
            dtlsParameters: dtlsParameters,
          })
            .then(callback)
            .catch(errback);
        }
      );

      consumerTransport.current.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            console.log("subscribing...");
            break;

          case "connected":
            console.log("subscribed");
            //consumeCurrentProducers(clientId);
            break;

          case "failed":
            console.log("failed");
            producerTransport.current.close();
            break;

          default:
            break;
        }
      });

      consumeCurrentProducers(clientId.current);
    }
  }

  async function loadDevice(routerRtpCapabilities) {
    try {
      device.current = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        console.error("browser not supported");
      }
    }
    await device.current.load({ routerRtpCapabilities });
  }

  function sendRequest(type, data) {
    return new Promise((resolve, reject) => {
      socketRef.current.emit(type, data, (err, response) => {
        if (!err) {
          // Success response, so pass the mediasoup response to the local Room.
          resolve(response);
        } else {
          reject(err);
        }
      });
    });
  }

  async function consumeCurrentProducers(clientId) {
    console.log("-- try consuleAll() --");
    const remoteInfo = await sendRequest("getCurrentProducers", {
      localId: clientId,
    }).catch((err) => {
      console.error("getCurrentProducers ERROR:", err);
      return;
    });
    //console.log('remoteInfo.producerIds:', remoteInfo.producerIds);
    console.log("remoteInfo.remoteVideoIds:", remoteInfo.remoteVideoIds);
    console.log("remoteInfo.remoteAudioIds:", remoteInfo.remoteAudioIds);
    consumeAll(
      consumerTransport.current,
      remoteInfo.remoteVideoIds,
      remoteInfo.remoteAudioIds
    );
  }

  function consumeAll(transport, remoteVideoIds, remotAudioIds) {
    console.log("----- consumeAll() -----");

    remoteVideoIds.forEach((rId) => {
      consumeAdd(transport, rId, null, "video", MODE_STREAM).then((resp) => {
        consumeAdd(transport, rId, null, "video", MODE_SHARE_SCREEN);
      });
    });
    let audioIdsCount = 0;
    remotAudioIds.forEach((rId) => {
      consumeAdd(transport, rId, null, "audio", MODE_STREAM).then((resp) => {
        consumeAdd(transport, rId, null, "audio", MODE_SHARE_SCREEN);
      });
    });
  }

  function disconnectSocket() {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      clientId.current = null;
      console.log("socket.io closed..");
    }
  }

  function removeConsumer(id, kind, mode) {
    if (mode == undefined) {
      return false;
    }
    if (kind === "video") {
      if (mode == MODE_STREAM) {
        delete videoConsumers.current[id];
      } else {
        delete videoConsumers.current[id][mode];
      }

      console.log(
        "videoConsumers count=" + Object.keys(videoConsumers.current).length
      );
    } else if (kind === "audio") {
      if (mode == MODE_STREAM) {
        delete audioConsumers.current[id];
      } else {
        delete audioConsumers.current[id][mode];
      }

      console.log(
        "audioConsumers count=" + Object.keys(audioConsumers.current).length
      );
    } else {
      console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  // function getConsumer(id: any, kind: any) {
  //     if (kind === 'video') {
  //         return videoConsumers.current[id];
  //     } else if (kind === 'audio') {
  //         return audioConsumers.current[id];
  //     } else {
  //         console.warn('UNKNOWN consumer kind=' + kind);
  //     }
  // }

  function addConsumer(id, consumer, kind, mode) {
    if (id === clientId.current) {
      return false;
    }
    if (kind === "video") {
      if (videoConsumers.current[id] == undefined) {
        videoConsumers.current[id] = {};
      }
      videoConsumers.current[id][mode] = consumer;
      console.log(
        "videoConsumers count=" + Object.keys(videoConsumers.current).length
      );
    } else if (kind === "audio") {
      if (audioConsumers.current[id] == undefined) {
        audioConsumers.current[id] = {};
      }
      audioConsumers.current[id][mode] = consumer;

      console.log(
        "audioConsumers count=" + Object.keys(audioConsumers.current).length
      );
    } else {
      console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  const connectSocket = () => {
    if (socketRef.current == null) {
      const io = socketIOClient(config.SERVER_ENDPOINT + "/video-conference");
      socketRef.current = io;
    }

    return new Promise((resolve, reject) => {
      const socket = socketRef.current;

      socket.on("connect", async function (evt) {
        console.log("socket.io connected(). prepare room=%s", roomName);
        // await sendRequest("prepare_room", { roomId: roomName, username });
      });
      socket.on("error", function (err) {
        console.error("socket.io ERROR:", err);
        reject(err);
      });
      socket.on("message", function (message) {
        console.log("socket.io message:", message);
        if (message.type === "welcome") {
          if (socket.id !== message.id) {
            console.warn(
              "WARN: something wrong with clientID",
              socket.io,
              message.id
            );
          }

          clientId.current = message.id;
          console.log("connected to server. clientId=" + clientId.current);
          resolve();
        } else {
          console.error("UNKNOWN message from server:", message);
        }
      });
      socket.on("newProducer", function (message) {
        console.log("socket.io newProducer:", message);
        const remoteId = message.socketId;
        const prdId = message.producerId;
        const kind = message.kind;
        const mode = message.mode;
        const username = message.username;

        if (kind === "video") {
          console.log(
            "--try consumeAdd remoteId=" +
              remoteId +
              ", prdId=" +
              prdId +
              ", kind=" +
              kind
          );
          consumeAdd(
            consumerTransport.current,
            remoteId,
            prdId,
            kind,
            mode,
            username
          );
        } else if (kind === "audio") {
          //console.warn('-- audio NOT SUPPORTED YET. skip remoteId=' + remoteId + ', prdId=' + prdId + ', kind=' + kind);
          console.log(
            "--try consumeAdd remoteId=" +
              remoteId +
              ", prdId=" +
              prdId +
              ", kind=" +
              kind
          );
          consumeAdd(
            consumerTransport.current,
            remoteId,
            prdId,
            kind,
            mode,
            username
          );
        }
      });

      socket.on("producerClosed", function (message) {
        console.log("socket.io producerClosed:", message);
        const localId = message.localId;
        const remoteId = message.remoteId;
        const kind = message.kind;
        const mode = message.mode;
        console.log(
          "--try removeConsumer remoteId=%s, localId=%s, track=%s",
          remoteId,
          localId,
          kind
        );
        removeConsumer(remoteId, kind, mode);
        removeRemoteVideo(remoteId, mode);
      });
      // socket.on('shareScreenClosed', function (payload: any) {
      //     console.log('socket.io shareScreenClosed:', payload);
      //     const callerID = payload.callerID;

      //     removeConsumer(callerID, 'video', MODE_SHARE_SCREEN);
      //     removeConsumer(callerID, 'audio', MODE_SHARE_SCREEN);
      //     removeRemoteVideoByMode(callerID, MODE_SHARE_SCREEN);
      // });
    });
  };

  useEffect(() => {
    userSocket = socketIOClient(config.SERVER_ENDPOINT);
  }, []);

  // call handleDisconnect on component unmount
  // useEffect(() => {
  //   return () => {
  //     handleDisconnect();
  //   };
  // }, []);

  if (!isConnected)
    return (
      <UserInfoForm
        {...{
          roomName,
          handleRoomName,
          handleConnect,

          username,
          handleUsername,

          // media control
          handleStartMedia,
          localVideo,

          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
        }}
      />
    );

  return (
    <div
      className="Transition-1"
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: "grid",
        // gridTemplateColumns: "1fr 30rem",
        gridTemplateColumns:
          show_chat || show_participants ? "1fr 30rem" : "1fr 0rem",
        background: "var(--background-color1)",
        color: "var(--text-color2)",
      }}
    >
      <VideoArea
        {...{
          participants:
            focused_participant === null ? remoteVideos : focused_participant,
          focused_participant,
          setFocusedParticipant,
          playVideo,

          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
          // share screen control
          localScreen,
          localStreamScreen,
          isShareScreen,
          handleStartScreenShare,
          handleDisconnectScreenShare,

          handleDisconnect,

          // right content control
          show_chat,
          setShowChat,
          show_participants,
          setShowParticipants,
        }}
      />
      <RightContentWrapper
        {...{
          participants: remoteVideos,
          focused_participant,
          setFocusedParticipant,
          show_chat,
          setShowChat,
          show_participants,
          setShowParticipants,
          playVideo,
        }}
      />
    </div>
  );
}
