import { Container } from "@mui/material";
import MVPItem from "./MVPItem";

import call_icon from "assets/static/mvp_icons/call.svg";
import share_screen_icon from "assets/static/mvp_icons/share_screen.svg";
import chat_icon from "assets/static/mvp_icons/chat.svg";
import privacy_icon from "assets/static/mvp_icons/privacy.svg";

const { default: CustomSection } = require("components/CustomSection");

const MVPSection = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color0)"
      id="home"
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <MVPItem
          title="Videochamadas Simples e Confiáveis"
          description="Nosso site oferece videochamadas em tempo real simples e confiáveis, permitindo que você se conecte facilmente com amigos, familiares e colegas de trabalho em todo o mundo. Com uma interface intuitiva e uma infraestrutura robusta, garantimos uma experiência de chamada suave e estável."
          icon={call_icon}
        />
        <MVPItem
          title="Compartilhamento de Tela e Colaboração"
          description="Além das videochamadas, nosso site permite que você compartilhe sua tela com outros participantes. Isso é perfeito para apresentações, demonstrações de produtos ou colaboração em projetos. Colabore de forma eficiente e compartilhe informações importantes em tempo real."
          icon={share_screen_icon}
          align_left={false}
        />
        <MVPItem
          title="Chat por Texto em Tempo Real"
          description="Além das videochamadas, nosso site também oferece um recurso de chat por texto em tempo real. Você pode enviar mensagens instantâneas para outros participantes durante as videochamadas ou utilizar o chat separadamente para se comunicar com colegas quando uma chamada não for necessária."
          icon={chat_icon}
        />
        <MVPItem
          title="Segurança e Privacidade"
          description="Entendemos a importância da segurança e privacidade durante as videochamadas. Por isso, implementamos medidas rigorosas para proteger suas informações e garantir que suas chamadas sejam privadas. Utilizamos criptografia de ponta a ponta e adotamos práticas de segurança robustas para manter seus dados seguros."
          icon={privacy_icon}
          align_left={false}
        />
      </Container>
    </CustomSection>
  );
};

export default MVPSection;
