import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const FAQItem = ({ question, answer, index }) => {
  const answer_ref = useRef(null);

  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [first_load, setFirstLoad] = useState(true);

  useEffect(() => {
    // get collapse height
    setTimeout(() => {
      setHeight(answer_ref?.current?.scrollHeight);
      setFirstLoad(false);
    }, 1000);
  }, []);

  const handleOpenCollapse = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "var(--background-color1)",
          borderRadius: "1rem",
          padding: "1.4rem 2rem",
          gap: "1rem",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "var(--background-color2)",
          },
          "&:active": {
            transform: "scale(0.98)",
          },
        }}
        onClick={handleOpenCollapse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.1rem",
              backgroundColor: "rgba(var(--accent-color1), 0.2)",
              height: "2rem",
              width: "2rem",
              borderRadius: "0.8rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--text-color1)",
            }}
          >
            {index + 1}
          </span>
  
          {/* <span
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              width: "100%",
            }}
          >
            {question}
          </span> */}
          <Box
          component={"span"}
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "1.1rem",
                sm: "1.3rem",
              },
              width: "100%",
            }}
          >
            {question}
          </Box>
        </div>

        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            backgroundColor: "rgba(var(--accent-color1), 0.2)",
            height: "2rem",
            width: "2rem",
            borderRadius: "0.8rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "var(--text-color1)",
          }}
        >
          <KeyboardArrowUpIcon sx={{ transform: open ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.5s ease" }} />
        </span>
      </Box>

      <Box
        ref={answer_ref}
        sx={{
          display: "flex",
          backgroundColor: "rgba(var(--accent-color1), 0.28)",
          borderRadius: "1rem",
          gap: "1rem",
          maxWidth: {
            xs: "100%",
            sm: "80%",
          },
          alignSelf: "flex-end",
          maxHeight: open && !first_load ? height +22.4 : 0,
          overflow: "hidden",
          transition: "max-height 0.5s ease",
          backdropFilter: "blur(2rem)",
        }}
      >
        <span
          style={{
            fontSize: "1.1rem",
            color: "white",
            padding: "1.4rem 2rem",
          }}
        >
          {answer}
        </span>
      </Box>
    </div>
  );
};

export default FAQItem;
