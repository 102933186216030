import {
  ChatText,
  Gear,
  Microphone,
  MicrophoneSlash,
  Screencast,
  UsersThree,
  VideoCamera,
  VideoCameraSlash,
} from "@phosphor-icons/react";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import BackHandIcon from "@mui/icons-material/BackHand";
import ControlButton from "components/ControlButtom";

export default function VideoAreaBottomControlls({
  // streaming control
  localStream,
  enableVideoStream,
  handleEnableVideoStream,
  enableAudioStream,
  handleEnableAudioStream,
  isShareScreen,
  handleStartScreenShare,
  handleDisconnectScreenShare,
  handleDisconnect,

  // right content control
  show_chat,
  setShowChat,
  show_participants,
  setShowParticipants,
}) {
  return (
    <div
      style={{
        height: "max-content",
        width: "100%",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "1.4rem",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <ControlButton tooltip="Perfil" bgImage="https://i.pravatar.cc/300" />
        <ControlButton tooltip="Configurações" icon={<Gear weight="fill" />} />
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <ControlButton
          tooltip={enableVideoStream ? "Desligar câmera" : "Ligar câmera"}
          icon={
            enableVideoStream ? (
              <VideoCamera weight="fill" />
            ) : (
              <VideoCameraSlash />
            )
          }
          onClick={() =>
            enableVideoStream
              ? handleEnableVideoStream(localStream.current, false)
              : handleEnableVideoStream(localStream.current, true)
          }
        />
        <ControlButton
          tooltip={enableAudioStream ? "Desligar microfone" : "Ligar microfone"}
          icon={
            enableAudioStream ? (
              <Microphone weight="fill" />
            ) : (
              <MicrophoneSlash />
            )
          }
          onClick={() =>
            enableAudioStream
              ? handleEnableAudioStream(localStream.current, false)
              : handleEnableAudioStream(localStream.current, true)
          }
        />
        <ControlButton
          // tooltip="Compartilhar tela"
          tooltip={
            isShareScreen ? "Desconectar compartilhamento" : "Compartilhar tela"
          }
          icon={
            isShareScreen ? (
              <Screencast weight="fill" />
            ) : (
              <Screencast weight="regular" />
            )
          }
          onClick={() =>
            isShareScreen
              ? handleDisconnectScreenShare()
              : handleStartScreenShare()
          }
        />
        <ControlButton tooltip="Pedir a palavra" icon={<BackHandIcon />} />
        <ControlButton
          tooltip="Sair da chamada"
          icon={
            <CallEndIcon
              sx={{
                color: "#fc3d3d",
              }}
            />
          }
          onClick={handleDisconnect}
        />
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <ControlButton
          tooltip={show_chat ? "Esconder chat" : "Mostrar chat"}
          icon={show_chat ? <ChatText weight="fill" /> : <ChatText />}
          onClick={() => {
            if (show_participants) setShowParticipants(false);
            setShowChat(!show_chat);
          }}
        />
        <ControlButton
          tooltip={
            show_participants
              ? "Esconder participantes"
              : "Mostrar participantes"
          }
          icon={
            show_participants ? <UsersThree weight="fill" /> : <UsersThree />
          }
          onClick={() => {
            if (show_chat) setShowChat(false);
            setShowParticipants(!show_participants);
          }}
        />
      </div>
    </div>
  );
}
