import React, { useEffect, useRef } from "react";
import ChatRoom from "./ChatRoom";
import VideoArea from "./VideoArea";
import UserInfoForm from "./UserInfoForm";
import RightContentWrapper from "./RightContentWrapper";
import { useSelector } from "react-redux";
import './style.css';
import io from 'socket.io-client';
import { config } from "app.config";
import { get } from "utilities/requests";
import { useParams } from "react-router-dom";

const MODE_STREAM = "stream";
const MODE_SHARE_SCREEN = "share_screen";

const configuration = {
  iceServers: [
    { urls: "stun:stun.1.google.com:19302" },
    {
      'urls': 'turn:turnserver.ontest.com.br:3478?transport=udp',
      'credential': 'Coconuts69341228#',
      'username': 'onteste_projetos'
    },
  ],
  sdpSemantics: 'unified-plan'
};

let silence = () => {
  let ctx = new AudioContext(), oscillator = ctx.createOscillator();
  let dst = oscillator.connect(ctx.createMediaStreamDestination());
  oscillator.start();
  return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
}

let black = ({ width = 640, height = 480 } = {}) => {
  let canvas = Object.assign(document.createElement("canvas"), { width, height });
  canvas.getContext('2d').fillRect(0, 0, width, height);
  let stream = canvas.captureStream();
  return Object.assign(stream.getVideoTracks()[0], { enabled: false });
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}


const admin_id = uuidv4();



export default function MeetingRoom(props) {
  console.log(props)
  const [focused_participant, setFocusedParticipant] = React.useState(null);
  let userSocket = null;
  
  const localScreen = React.useRef();
  const localStreamScreen = React.useRef();

  const participants = React.useRef();

  const isConnectedRef = React.useRef();
  const [show_chat, setShowChat] = React.useState(false);
  const [show_info, setShowInfo] = React.useState(false);
  const sala = useRef();


  const [show_participants, setShowParticipants] = React.useState(true);
  const timeout_join = useRef();

  const localVideo = React.useRef();
  const localStream = React.useRef();
  const clientId = React.useRef();
  const device = React.useRef();
  const producerTransport = React.useRef();
  const videoProducer = React.useRef({});
  const audioProducer = React.useRef({});
  const consumerTransport = React.useRef();
  const videoConsumers = React.useRef({});
  const audioConsumers = React.useRef({});
  const consumersStream = React.useRef({});
  const list_peers = React.useRef();

  const [useVideo, setUseVideo] = React.useState(true);
  const [useAudio, setUseAudio] = React.useState(true);
  const [isStartMedia, setIsStartMedia] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);
  const [remoteVideos, setRemoteVideos] = React.useState({});
  const [isShareScreen, setIsShareScreen] = React.useState(false);
  const [roomName, setRoomName] = React.useState("");
  const [username, setUsername] = React.useState("");

  const [start_media, setStartMedia] = React.useState(true);
  const [room_error, setRoomError] = React.useState(false);


  const [messageWarning, setMessageWarning] = React.useState("");

  // ============ STREAMING CONTROL START ==========
  const [enableVideoStream, setEnableVideoStream] = React.useState(useVideo);
  const [enableAudioStream, setEnableAudioStream] = React.useState(useAudio);

  const [enableVideoShare, setEnableVideoShare] = React.useState(useVideo);
  const [enableAudioShare, setEnableAudioShare] = React.useState(useAudio);
  const params = useParams();
  const socket = useRef();
  const connect = useRef();

  const user = useSelector(store => store.AppReducer.user);

  const replaceVideoTracks = (type, id_track, new_track) => {
    Object.keys(list_peers.current).map((key) => {
      if (list_peers.current[key] != undefined) {
        list_peers.current[key].getSenders().map(async (sender) => {
          if (sender.track.kind == type && id_track == sender.track.id) {
            await sender.replaceTrack(new_track)
          }
          return sender;
        })
      }
    })
  }

  const getAdminId = ()=>{
    return admin_id;
  }



  const handleEnableVideoStream = async (stream, cond) => {
    if (!stream) {
      return false;
    }
    if(participants.current[getAdminId()]==undefined){
      participants.current[getAdminId()] = {
        name:username, id: getAdminId(), type: 'Avaliador',
        stream: stream,
        video_track_cam: null,
        video_track_screen: null,
        audio_track: null,
        video_cam: enableVideoStream, audio: enableAudioStream, screen: isShareScreen
      }
    }
   
    if (cond == false) {
      stream.getVideoTracks()[0].stop();
      console.log('stop')
      participants.current[getAdminId()].video_cam = false;
      
      let remotes = { ...participants.current }
      setRemoteVideos(remotes);
      setEnableVideoStream(cond);
      if(isConnected){
        // replaceVideoTracks('video',stream.getVideoTracks()[0].id,black());
        socket.current.emit('update_config_call',{...participants.current[getAdminId()],video_cam:cond,sala_id:sala.current.id});
      }
    }
    else {
      navigator.mediaDevices.getUserMedia({ video: {
        width: 640,
        height: 480,
        facingMode: "user",
        frameRate: 24,
    } }).then((stream_new)=>{
        participants.current[getAdminId()].video_cam = true;
        participants.current[getAdminId()].video_track_cam = stream_new.getVideoTracks()[0];
        if(isConnected){
          stream_new.addTrack(stream.getVideoTracks()[1]);
        }
        stream_new.addTrack(stream.getAudioTracks()[0]);

        participants.current[getAdminId()].stream = stream_new;
        localStream.current = stream_new;
        consumersStream.current = stream_new;
        
        let remotes = { ...participants.current }
        if(isConnected==true){
          replaceVideoTracks('video',stream.getVideoTracks()[0].id,stream_new.getVideoTracks()[0]);
          socket.current.emit('update_config_call',{...participants.current[getAdminId()],video_cam:cond,sala_id:sala.current.id});
        }
        else{
        localVideo.current.srcObject=stream_new;
        }
        // stream.getVideoTracks()[0].stop();

       
        setRemoteVideos(remotes);
        setEnableVideoStream(cond);
      }).catch((e)=>{
        
      })
      

    }
    // stream.getVideoTracks()[0].enabled = cond;
  };
  const handleEnableAudioStream = (stream, cond) => {
    if (!stream) {
      return false;
    }
    if(participants.current[getAdminId()]==undefined){
      participants.current[getAdminId()] = {
        name:username, id: getAdminId(), type: 'Avaliador',
        stream: stream,
        video_track_cam: null,
        video_track_screen: null,
        audio_track: null,
        video_cam: enableVideoStream, audio: enableAudioStream, screen: isShareScreen
      }
    }
    if(stream.getAudioTracks()[0].readyState=="ended" && cond==true){
      console.log('entrou aqui')
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream_new)=>{
        participants.current[getAdminId()].audio=cond;
        stream.getAudioTracks()[0].enabled = cond;
        stream_new.addTrack(stream.getVideoTracks()[0])
        if(isConnected){
          stream_new.addTrack(stream.getVideoTracks()[1])
          replaceVideoTracks('audio',stream.getAudioTracks()[0].id,stream_new.getAudioTracks()[0]);
          socket.current.emit('update_config_call',{...participants.current[getAdminId()],audio:cond,sala_id:sala.current.id});
        }

        participants.current[getAdminId()].stream = stream_new;
        localStream.current = stream_new;
        consumersStream.current = stream_new;
        let remotes = { ...participants.current }

        setRemoteVideos(remotes);
        setEnableAudioStream(cond);

      }).catch((e)=>{
        
      })
    }
    else{
      participants.current[getAdminId()].audio=cond;
        stream.getAudioTracks()[0].enabled = cond;
        let remotes = { ...participants.current }

      if(isConnected){
        socket.current.emit('update_config_call',{...participants.current[getAdminId()],audio:cond,sala_id:sala.current.id});
      }
      setRemoteVideos(remotes);
      setEnableAudioStream(cond);

    }
   
   

  };
  const handleEnableVideoShare = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getVideoTracks()[0].enabled = cond;
    setEnableVideoShare(cond);
  };
  const handleEnableAudioShare = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getAudioTracks()[0].enabled = cond;
    setEnableAudioShare(cond);
  };

  // ============STREAMING CONTROL END ==========
  // ============ ROOM START ==========

  const handleRoomName = (e) => {
    setRoomName(e.target.value);
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };
  // ============ ROOM END ==========

  // ============ SHARE SCREEN START ==========
  const handleStartScreenShare = () => {
    if (localStreamScreen.current) {
      console.warn("WARN: local media ALREADY started");
      return;
    }

    const mediaDevices = navigator.mediaDevices;
    mediaDevices
      .getDisplayMedia({  video: {
        width: 1280,
        height: 960,
        frameRate: 24,
      } })
      .then((stream) => {
        localStreamScreen.current = stream;

        // playVideo(localScreen.current, localStreamScreen.current, 0);
        handleConnectScreenShare();
        const screenTrack = stream.getVideoTracks()[0];
        screenTrack.onended = function () {
          handleDisconnectScreenShare();
        };


          participants.current[getAdminId()].video_show='screen';
          participants.current[getAdminId()].screen = true;
          participants.current[getAdminId()].video_track_screen = screenTrack;
          let stream_new=new MediaStream();
          let track_old = participants.current[getAdminId()].stream.getVideoTracks()[1];

          stream_new.addTrack(participants.current[getAdminId()].stream.getAudioTracks()[0]);
          stream_new.addTrack(participants.current[getAdminId()].stream.getVideoTracks()[0]);
          stream_new.addTrack(screenTrack);

  
          participants.current[getAdminId()].stream = stream_new;
          localStream.current = stream_new;
          consumersStream.current = stream_new;
          
          let remotes = { ...participants.current }
            replaceVideoTracks('video',track_old.id,screenTrack);
            track_old.stop();
          // else{
          // localVideo.current.srcObject=stream_new;
          // }
          setRemoteVideos(remotes);
        setIsShareScreen(true);
      socket.current.emit('update_config_call',{...participants.current[getAdminId()],sala_id:sala.current.id});
        setFocusedParticipant({
          [getAdminId()]:participants.current[getAdminId()],type:'screen'
        })

      })
      .catch((err) => {
        console.error("media ERROR:", err);
      });
  };

  async function handleConnectScreenShare() {
    if (!localStreamScreen.current) {
      console.warn("WARN: local media NOT READY");
      return;
    }

    // // --- connect socket.current.io ---
    // await connectSocket().catch((err: any) => {
    //     console.error(err);
    //     return;
    // });

    // console.log('connected');

    // --- get capabilities --

  }

  useEffect(()=>{
    isConnectedRef.current=isConnected;
  },[isConnected])

  function handleStopScreenShare() {
    if (localStreamScreen.current) {
      stopLocalStream(localStreamScreen.current);
      localStreamScreen.current = null;
      setIsShareScreen(false);
    }
  }
  async function handleDisconnectScreenShare() {
    if(participants.current[getAdminId()]!=undefined){

    participants.current[getAdminId()].screen = false;
    if(participants.current[getAdminId()].video_track_screen!=null){
      participants.current[getAdminId()].video_track_screen.stop();
    }
    participants.current[getAdminId()].video_track_screen = null;
    participants.current[getAdminId()].video_show='camera';

    localStreamScreen.current=null;
    let remotes = { ...participants.current }
    // else{
    // localVideo.current.srcObject=stream_new;
    // }
    setRemoteVideos(remotes);
  setIsShareScreen(false);
  socket.current.emit('update_config_call',{...participants.current[getAdminId()],sala_id:sala.current.id});
}

  }

  const getRoom = () =>{

    get('getRoom/' + params.id).then((resp) => {
      console.log(resp)
      if(resp.room){
        sala.current = resp.room;
        console.log(config.SERVER_PUBLIC_ENDPOINT)
        socket.current = io.connect(config.SERVER_PUBLIC_ENDPOINT, { "transports": ['websocket'], query: {  } });
    socket.current.on("connect", function (client) {
      connect.current = true;
      setIsStartMedia(true);

      // this.socket.emit('join_stream_admin', { sala_id: this.state.sala_select.id, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id })
      socket.current.emit("join", { name: 'Admin',  sala_id: sala.current.id, admin_id: getAdminId() });

  });

      }
      else{
        handleStopMedia();
        setRoomError(true);
      }
    }).catch((e) => {
      setTimeout(()=>{
        getRoom();
      },5000);
      handleStopMedia();
    })
  }

  // ============ SHARE SCREEN END ==========

  // ============ MEDIA START ==========
  const handleStartMedia = () => {
    setMessageWarning('');
    if (localStream.current) {
      setIsStartMedia(true);

      console.warn("WARN: local media ALREADY started");
      return;
    }
    console.log('starting media');
    setStartMedia(false)

    navigator.mediaDevices
      .getUserMedia({ audio: useAudio, video: useVideo==true? {
        width: 640,
        height: 480,
        facingMode: "user",
        frameRate: 24,
    }:false })
      .then((stream) => {
        localStream.current = stream;
        playVideo(localVideo.current, localStream.current, 0);
        setEnableAudioStream(true);
        setEnableVideoStream(true);
        getRoom();
      })
      .catch((err) => {
        // setIsStartMedia(true);
        setEnableAudioStream(false);
        setEnableVideoStream(false);
        getRoom();
        console.error("media ERROR:", err);
      });
  };

  function handleStopMedia() {
    if (localStream.current) {
      console.log('stop media')
      stopLocalStream(localStream.current);
      localStream.current = null;
      setIsStartMedia(false);
    }
  }

  function handleDisconnect() {
    if(isConnected && socket.current){
      socket.current.emit('exit_call', {  sala_id: sala.current.id, id: getAdminId() })
    }
    handleStopMedia();
    handleStopScreenShare();
    setShowChat(false);
    setFocusedParticipant(null);

    disconnectSocket();
    setIsConnected(false);
    if(socket.current!=null){
    socket.current.off('connect', connect_event)

    socket.current.off('disconnect', disconnect_event)
    socket.current.removeAllListeners('join_call');
      socket.current.removeAllListeners('new_participant');
      socket.current.removeAllListeners('remove_participant');
      socket.current.removeAllListeners('candidate_call');
      socket.current.removeAllListeners('sdp_call');
      socket.current.removeAllListeners('desc_call');
      socket.current.removeAllListeners('answer_call');
      socket.current.removeAllListeners('update_config_call');
      socket.current.disconnect();

    }
  }

  function playVideo(element, stream, volume = 0) {
    try{
      
    // if (element.srcObject) {
    //   console.warn("element ALREADY playing, so ignore");
    //   return;
    // }
    element.srcObject = stream;
    console.log(element);
    console.log(stream);

    element.volume = volume;
    if(element){
      return element.play();

    }
  }
catch(e){
  
}
  }

  function pauseVideo(element) {
    try {
      element.pause();
      element.srcObject = null;
    } catch (err) {
      console.error("pauseVideo ERROR:", err);
    }
  }

  function stopLocalStream(stream) {
    let tracks = stream.getTracks();
    if (!tracks) {
      console.warn("NO tracks");
      return;
    }

    tracks.forEach((track) => track.stop());
  }

  function addRemoteTrack(id, track, mode) {
  }

  // function addRemoteVideo(id) {
  //   let existElement = findRemoteVideo(id);
  //   if (existElement) {
  //     console.warn("remoteVideo element ALREADY exist for id=" + id);
  //     return existElement;
  //   }

  //   let element = document.createElement("video");
  //   return element;
  // }

  // function findRemoteVideo(id) {
  //   let element = remoteVideos.current[id];
  //   return element;
  // }

  // function removeRemoteVideoByMode(id: any, mode: string) {
  //     console.log(' ---- removeRemoteVideo() id=' + id);
  //     delete consumersStream.current[id][mode];
  //     setRemoteVideos((peers: any) => {
  //         const newPeers: any = peers;
  //         delete newPeers[id][mode];

  //         return { ...peers, ...newPeers };
  //     });
  // }

  function removeRemoteVideo(id, mode) {
    console.log(" ---- removeRemoteVideo() id=" + id);
    if (mode == MODE_STREAM) {
      delete consumersStream.current[id];
    } else {
      delete consumersStream.current[id][mode];
    }

    setRemoteVideos((peers) => {
      const newPeers = peers;
      delete newPeers[id];

      return { ...consumersStream.current };
    });
    // if (element) {
    //     element.pause();
    //     element.srcObject = null;
    //     remoteContainer.removeChild(element);
    // } else {
    //     console.log('child element NOT FOUND');
    // }
  }

  function removeAllRemoteVideo() {
    console.log(" ---- removeAllRemoteVideo() id");
    consumersStream.current = {};
    setRemoteVideos((peers) => {
      const newPeers = {};

      return { ...newPeers };
    });
    // while (remoteContainer.firstChild) {
    //     remoteContainer.firstChild.pause();
    //     remoteContainer.firstChild.srcObject = null;
    //     remoteContainer.removeChild(remoteContainer.firstChild);
    // }
  }

  async function consumeAdd(
    transport,
    remoteSocketId,
    prdId,
    trackKind,
    mode = MODE_STREAM
  ) {

  }

  const onIceCandidate = function (peer, evt, admin_id, receive_id) {
    if(receive_id=='server'){
      socket.current.emit("candidate_call_server", JSON.stringify({ "candidate": evt.candidate, sender_id: admin_id, receive_id: receive_id, sala_id: sala.current.id }));
    }
    else{
      socket.current.emit("candidate_call", JSON.stringify({ "candidate": evt.candidate, sender_id: admin_id, receive_id: receive_id, sala_id: sala.current.id }));
    }
  }


  const create_peer = (id) => {
    try{
    // let stream = this.choose_stream(id);

    // if(this.list_peers.current[id]!=undefined && this.list_peers.current[id].connectionState=='connected'){
    //     return;
    // }
    if (list_peers.current[id] != undefined) {
      list_peers.current[id].close();
    }
    list_peers.current[id] = new RTCPeerConnection(configuration);

    list_peers.current[id].onicecandidate = (evt) => {
      if (!evt.candidate) return;
      // console.log("onicecandidate called");
      onIceCandidate(list_peers.current[id], evt, getAdminId(), id);
    };
    list_peers.current[id].onaddstream = (evt) => {
      console.log("onaddstream called");
      consumersStream.current[id] = evt.stream;
      console.log('remoteVideos2', remoteVideos, id)
      console.log(participants.current[id])
      participants.current[id].stream = evt.stream;
      participants.current[id].video_track_cam = evt.stream.getVideoTracks()[0];
      participants.current[id].video_track_screen = evt.stream.getVideoTracks()[1];
      participants.current[id].audio_track = evt.stream.getAudioTracks()[0];
      
      console.log('participant1', participants.current[id])

      let remotes = { ...participants.current };
      setRemoteVideos(remotes)
      // document.getElementById("remoteview").srcObject = evt.stream;
    };
    // list_peers.current[id].oniceconnectionstatechange = function() {
    //     console.log(list_peers.current[id].iceConnectionState);
    // }
    list_peers.current[id].onconnectionstatechange = (e) => {
      console.log(e);
      console.log(list_peers.current[id].connectionState);
    }
    // let stream = new MediaStream();
    // this.props.stream.getTracks().forEach(track => stream.addTrack(track));
    // if(this.props.stream_screen!=null) this.props.stream_screen.getTracks().forEach(track => stream.addTrack(track));
    // this.list_peers.current[id].addStream(localStream.current);
    if(id=='server'){
      console.log('add track')
      localStream.current.getTracks().forEach(track => list_peers.current[id].addTrack(track, localStream.current));
    }
    else{
      list_peers.current[id].addStream(localStream.current);
    }



    // for (const track of this.props.stream.getTracks()) {
    //     list_peers.current[id].addTrack(track, this.props.stream);
    // }

    list_peers.current[id].createOffer(id=='server'?{
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1
    }:{}).then(function (desc) {
      try {
        // console.log('sdp coonect');
        // if (good == false) {
        //     desc.sdp = this.handle_offer_sdp(desc)
        // }
        console.log('peer');

        list_peers.current[id].setLocalDescription(new RTCSessionDescription(desc)).then((s) => {
          // console.log(s);
        }).catch(function (e) {
          console.log('Erro webrtc');
          console.log(e);
          list_peers.current[id].close();
          create_peer(id);
        });
        if(id=='server'){
          socket.current.emit("sdp_call_server", JSON.stringify({ "sdp": desc, sender_id: getAdminId(), receive_id: id, sala: sala.current.id }));
        }
        else{
          socket.current.emit("sdp_call", JSON.stringify({ "sdp": desc, sender_id: getAdminId(), receive_id: id, sala: sala.current.id }));
        }
      }
      catch (err) {
        // this.create_peer(id);
        console.log(err);
      }

    }).catch(function (e) {
      // console.log(e);
    });
  } catch(e){
    console.log(e);
  }
  }

  function connect_event(data) {
      console.log('connect_event','isConnected',isConnectedRef.current)
      setMessageWarning('');
      if(isConnectedRef.current){
        socket.current.emit('join_call', { video_cam: participants.current[getAdminId()].video_cam, audio: participants.current[getAdminId()].audio, screen: participants.current[getAdminId()].screen,type: 'Admin',  sala_id: sala.current.id, id: getAdminId(), name: username, id_adm: getAdminId() });
      }
  }

  const focused_participant_ref = useRef();

  useEffect(()=>{
    focused_participant_ref.current=focused_participant;
  },[focused_participant])



  function disconnect_event(data) {
    connect.current = false;
    setMessageWarning('Sua conexão parece estar instável');
  }

  async function handleConnect() {
    if (socket.current != null && connect.current == true) {

    setIsStartMedia(false);
    let stream = localStream.current;

    if (!localStream.current) {
      stream = new MediaStream();
      // console.warn("WARN: local media NOT READY");
      // return;
    }
    if (stream.getAudioTracks().length == 0) {
      let track = silence();
      track.stop();
      stream.addTrack(track)
    }
    for (let i = stream.getVideoTracks().length; i < 2; i++) {
      let track = black();
      track.stop();
      stream.addTrack(track)
    }
    localStream.current = stream;
    consumersStream.current[getAdminId()] = localStream.current;
    console.log(socket)
    console.log(localStream.current)

    console.log('connect')
      let participants_ = { ...participants.current };
      participants_[getAdminId()] = {
        name:username, id: getAdminId(), type: 'Avaliador',
        stream: localStream.current,
        video_track_cam: localStream.current.getVideoTracks()[0],
        video_track_screen: localStream.current.getVideoTracks()[1],
        audio_track: localStream.current.getAudioTracks()[0],
        video_cam: enableVideoStream, audio: enableAudioStream, screen: isShareScreen,
        video_show:'camera'
      }
      console.log('set remove video4', participants_)
      participants.current = participants_;
      setRemoteVideos(participants_);

      socket.current.removeAllListeners('join_call');
      socket.current.removeAllListeners('new_participant');
      socket.current.removeAllListeners('remove_participant');
      socket.current.removeAllListeners('candidate_call');
      socket.current.removeAllListeners('sdp_call');
      socket.current.removeAllListeners('desc_call');
      socket.current.removeAllListeners('answer_call');
      socket.current.removeAllListeners('update_config_call');
      socket.current.off('connect', connect_event)

      socket.current.off('disconnect', disconnect_event)

      socket.current.on('connect', connect_event)

      socket.current.on('disconnect', disconnect_event)

      socket.current.on('new_participant', (data) => {
        if (data.id != getAdminId()) {

          let participant = { video_show:data.screen==true?'screen':'camera',type:data.type, id: data.id, name: data.name, stream: null, audio_track: null, video_track_cam: null, video_track_screen: null, video_cam: data.video_cam, audio: data.audio, screen: data.screen };
          let participantes = { ...participants.current };
          participantes[data.id] = participant;
          participants.current = participantes;
          console.log('set remove video1', participantes)
          setRemoteVideos(participantes);
        }


      })

      socket.current.on('remove_participant', (data) => {
        console.log('removed_participant',data)
        if (data.id != getAdminId()) {
          let participantes = { ...participants.current };
          delete participantes[data.id];
          delete consumersStream.current[data.id];
          delete participants.current[data.id];

          console.log('set remove video2', participantes)

          setRemoteVideos(participantes);
          setFocusedParticipant(null);
          if(focused_participant_ref.current!=null && focused_participant_ref.current[data.id]!=undefined){
            setFocusedParticipant(null);

          }
        }
      })

      socket.current.on('update_config_call', (data) => {
        console.log('update_config_call',data)
        data.number.map((item) => {
          let id = item.identifier;
          if(id==getAdminId()){
            return;
          }
          if(participants.current[id].screen==false && item.screen==true){
            participants.current[id] = {...participants.current[id],...item,video_show:'screen'};
          }
          else{
            participants.current[id] = {...participants.current[id],...item};
          }
          let participantes = { ...participants.current };
          setRemoteVideos(participantes);
          if(focused_participant_ref.current!=null && focused_participant_ref.current[id]!=undefined){
            setFocusedParticipant({
              [participants.current[id].id]: participants.current[id],type:participants.current[id].screen==true?'screen':'video',
            });
          }
        })

      })

      socket.current.on('join_call', (data) => {
        clearTimeout(timeout_join.current);
        console.log('join_call', data);
        // create_peer('server');
        setIsStartMedia(true);
        setIsConnected(true)
        data.number.map((item) => {
          let id = item.identifier;
          let name = item.name;
          if (id != getAdminId()) {
            console.log('criando peer', id, getAdminId())
            let participant = { video_show:item.screen==true?'screen':'camera', id, name,type:item.type, stream: null, video_cam: item.video_cam, audio: item.audio, screen: item.screen, audio_track: null, video_track_cam: null, video_track_screen: null };
            participants.current[id] = participant;
            let participantes = { ...participants.current };
            setRemoteVideos(participantes);
            create_peer(id);
          }

        })

      })
      socket.current.on("candidate_call", function (msg) {
        // console.log("candidate received");
        if (list_peers.current[JSON.parse(msg).sender_id] != undefined && list_peers.current[JSON.parse(msg).sender_id].connectionState != 'closed') {
          list_peers.current[JSON.parse(msg).sender_id].addIceCandidate(new RTCIceCandidate(JSON.parse(msg).candidate));
        }

      });

      socket.current.on("answer_call", function (answer) {
        // console.log("answer received");
        // console.log(answer);

        list_peers.current[JSON.parse(answer).sender_id].setRemoteDescription(new RTCSessionDescription(JSON.parse(answer).sdp));
      });

      socket.current.on("sdp_call", function (msg) {
        console.log("sdp received");
        let id = JSON.parse(msg).sender_id;
        if (list_peers.current[id] != undefined) {
          list_peers.current[id].close();
        }
        list_peers.current[id] = new RTCPeerConnection(configuration);

        list_peers.current[id].onicecandidate = function (evt) {
          if (!evt.candidate) return;
          // console.log("onicecandidate called");
          onIceCandidate(list_peers.current[id], evt, getAdminId(), id);
        };

        list_peers.current[id].onaddstream = function (evt) {
          console.log("onaddstream called");
          // if (this.state.sala_select.alunos[id] != undefined) {

          //     this.state.sala_select.alunos[id].videoRef.current.srcObject = evt.stream;
          //     this.state.sala_select.alunos[id].stream = evt.stream;
          //     let user_id = id;
          //     this.audio_context_peers[user_id] = {}
          //     this.audio_context_peers[user_id]['context'] = new AudioContext();
          //     this.audio_context_peers[user_id]['analyser'] = this.audio_context_peers[user_id]['context'].createAnalyser();
          //     this.audio_context_peers[user_id]['frequencyData'] = null;
          //     this.audio_context_peers[user_id]['source'] = this.audio_context_peers[user_id]['context'].createMediaStreamSource(this.state.sala_select.alunos[id].stream);
          //     this.audio_context_peers[user_id]['source'].connect(this.audio_context_peers[user_id]['analyser']);
          //     this.audio_context_peers[user_id]['gainNode'] = this.audio_context_peers[user_id]['context'].createGain();
          //     this.audio_context_peers[user_id]['gainNode'].gain.value = 0.1; // setting it to 10%
          //     this.audio_context_peers[user_id]['gainNode'].connect(this.audio_context_peers[user_id]['context'].destination);
          //     // analyser.connect(context.destination);

          //     this.audio_context_peers[user_id]['context'].resume()
          //     this.audio_context_peers[user_id]['analyser'].fftSize = 32;
          //     this.audio_context_peers[user_id]['bufferLength'] = this.audio_context_peers[user_id]['analyser'].frequencyBinCount;
          //     this.audio_context_peers[user_id]['dataArray'] = new Uint8Array(this.audio_context_peers[user_id]['bufferLength']);
          //     this.audio_context_peers[user_id]['analyser'].getByteTimeDomainData(this.audio_context_peers[user_id]['dataArray']);
          //     this.audio_context_peers[user_id].id=this.uuidv4()
          //     // this.audio.srcObject = evt.stream
          //     this.draw_peer(user_id,this.audio_context_peers[user_id].id);

          //     console.log('full_screen_added1')
          //     // console.log(this.state.aluno_fullscreen,Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen]?.id,id)
          //     if (this.state.aluno_fullscreen != -1 && this.state.aluno_fullscreen == id) {
          //         console.log('full_screen_added2')
          //         console.log(evt.stream)
          //         this.fullscreen_ref.current.srcObject = evt.stream;
          //     }
          // }
          consumersStream.current[id] = evt.stream;
          console.log('remoteVideos1', remoteVideos, id)
          participants.current[id].stream = evt.stream;
          participants.current[id].video_track_cam = evt.stream.getVideoTracks()[0];

          participants.current[id].video_track_screen = evt.stream.getVideoTracks()[1];
          participants.current[id].audio_track = evt.stream.getAudioTracks()[0];
          
          let remotes = { ...participants.current };
          setRemoteVideos(remotes)
          console.log('participant2', participants.current[id])

          // document.getElementById("video_tag" + JSON.parse(msg).aluno_id).srcObject = evt.stream;
        };
        list_peers.current[id].addStream(localStream.current);

        // list_peers.current[id].oniceconnectionstatechange = function() {
        //     console.log(list_peers.current[id].iceConnectionState);
        // }
        // list_peers.current[id].onconnectionstatechange = function() {
        //     console.log(list_peers.current[id].connectionState);
        // }   
        var sessionDesc = new RTCSessionDescription(JSON.parse(msg).sdp);
        list_peers.current[id].setRemoteDescription(sessionDesc);
        list_peers.current[id].createAnswer().then(function (sdp) {
          // if(this.good_quality_student[id]!=true){
          //     sdp.sdp = this.handle_offer_sdp(sdp)
          // }
          // else{
          //     console.log('NAO ENTROU SDP')
          // }
          console.log('anwser sdp');
          list_peers.current[id].setLocalDescription(new RTCSessionDescription(sdp));
          socket.current.emit("answer_call", JSON.stringify({ "sdp": sdp, sender_id: getAdminId(), receive_id: id, sala_id: sala.current.id }));
        }).catch(function (e) {
          console.log(e);
        });
      });
      console.log('admin_id1', getAdminId())
      console.log('admin_id2', getAdminId())
      timeout_join.current=setTimeout(()=>{
        setIsStartMedia(true)
      },10000);

      socket.current.emit('join_call', { video_cam: enableVideoStream, audio: enableAudioStream, screen: isShareScreen,type: 'Avaliador',  sala_id: sala.current.id, id: getAdminId(), name: username });
    }





  }

  async function subscribe() {
    // console.log(socketRef.current);


    // --- get capabilities --

  }

  async function loadDevice(routerRtpCapabilities) {
    try {
      // device.current = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        console.error("browser not supported");
      }
    }
    await device.current.load({ routerRtpCapabilities });
  }

  function sendRequest(type, data) {

  }

  async function consumeCurrentProducers(clientId) {
    console.log("-- try consuleAll() --");
    const remoteInfo = await sendRequest("getCurrentProducers", {
      localId: clientId,
    }).catch(function (err) {
      console.error("getCurrentProducers ERROR:", err);
      return;
    });
    //console.log('remoteInfo.producerIds:', remoteInfo.producerIds);
    console.log("remoteInfo.remoteVideoIds:", remoteInfo.remoteVideoIds);
    console.log("remoteInfo.remoteAudioIds:", remoteInfo.remoteAudioIds);
    consumeAll(
      consumerTransport.current,
      remoteInfo.remoteVideoIds,
      remoteInfo.remoteAudioIds
    );
  }

  function consumeAll(transport, remoteVideoIds, remotAudioIds) {
    console.log("----- consumeAll() -----");

    remoteVideoIds.forEach((rId) => {
      consumeAdd(transport, rId, null, "video", MODE_STREAM).then((resp) => {
        consumeAdd(transport, rId, null, "video", MODE_SHARE_SCREEN);
      });
    });
    let audioIdsCount = 0;
    remotAudioIds.forEach((rId) => {
      consumeAdd(transport, rId, null, "audio", MODE_STREAM).then((resp) => {
        consumeAdd(transport, rId, null, "audio", MODE_SHARE_SCREEN);
      });
    });
  }

  function disconnectSocket() {
    Object.keys(list_peers.current).map((key) => {
      if (list_peers.current[key] != undefined) {
        list_peers.current[key].close();
      }
    })
    list_peers.current = {}
    participants.current = {}
    consumersStream.current = {}
    setRemoteVideos({});
  }

  function removeConsumer(id, kind, mode) {
    if (mode == undefined) {
      return false;
    }
    if (kind === "video") {
      if (mode == MODE_STREAM) {
        delete videoConsumers.current[id];
      } else {
        delete videoConsumers.current[id][mode];
      }

      console.log(
        "videoConsumers count=" + Object.keys(videoConsumers.current).length
      );
    } else if (kind === "audio") {
      if (mode == MODE_STREAM) {
        delete audioConsumers.current[id];
      } else {
        delete audioConsumers.current[id][mode];
      }

      console.log(
        "audioConsumers count=" + Object.keys(audioConsumers.current).length
      );
    } else {
      console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  // function getConsumer(id: any, kind: any) {
  //     if (kind === 'video') {
  //         return videoConsumers.current[id];
  //     } else if (kind === 'audio') {
  //         return audioConsumers.current[id];
  //     } else {
  //         console.warn('UNKNOWN consumer kind=' + kind);
  //     }
  // }

  function addConsumer(id, consumer, kind, mode) {
    if (id === clientId.current) {
      return false;
    }
    if (kind === "video") {
      if (videoConsumers.current[id] == undefined) {
        videoConsumers.current[id] = {};
      }
      videoConsumers.current[id][mode] = consumer;
      console.log(
        "videoConsumers count=" + Object.keys(videoConsumers.current).length
      );
    } else if (kind === "audio") {
      if (audioConsumers.current[id] == undefined) {
        audioConsumers.current[id] = {};
      }
      audioConsumers.current[id][mode] = consumer;

      console.log(
        "audioConsumers count=" + Object.keys(audioConsumers.current).length
      );
    } else {
      console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  const changeVideo = (type,id) => {
    participants.current[id].video_show=type;
    let remotes = {...participants.current};
    setRemoteVideos(remotes);
  };
  

  useEffect(() => {
    list_peers.current = {}
    participants.current = {}
    consumersStream.current = {}

   

    return () => {
      handleDisconnect();
    }
  }, []);

  // call handleDisconnect on component unmount
  // useEffect(() => {
  //   return () => {
  //     handleDisconnect();
  //   };
  // }, []);

  if (!isConnected)
    return (
  <>
  {room_error ==true && <div style={{position:'fixed',top:0,left:0,right:0,bottom:0,display:'flex',justifyContent:'center',alignItems:'center',zIndex:10,background: "var(--background-color1)",
        color: "var(--text-color2)",}}>
      <p style={{textAlign:"center",fontSize:18}}>Essa sala não existe. Verifique o link e tente novamente.</p>
  </div>}
      <UserInfoForm
        {...{
          roomName,
          handleRoomName,
          handleConnect,
          isStartMedia,
          username,
          handleUsername,

          // media control
          handleStartMedia,
          localVideo,
          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
          getAdminId:getAdminId
        }}
      />
  </>

    );

  return (
    <div
      className="Transition-1"
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: "grid",
        // gridTemplateColumns: "1fr 30rem",
        gridTemplateColumns:
            show_info || show_chat ? "1fr 30rem" : (show_participants  ==true?"1fr 20rem": "1fr 0rem"),
        background: "var(--background-color1)",
        color: "var(--text-color2)",
        zIndex:12
      }}
    >
      <VideoArea
        {...{
          participants:
            focused_participant === null ? remoteVideos : focused_participant,
          focused_participant,
          setFocusedParticipant,
          playVideo,
          getAdminId: getAdminId,
          messageWarning:messageWarning,
          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
          // share screen control
          localScreen,
          localStreamScreen,
          isShareScreen,
          handleStartScreenShare,
          handleDisconnectScreenShare,
          username,
          handleDisconnect,

          // right content control
          show_info,
          setShowInfo,
          show_chat,
          setShowChat,
          show_participants,
          setShowParticipants,

        }}
      />
      <RightContentWrapper
        {...{
          changeVideo,
          participants: remoteVideos,
          focused_participant,
          setFocusedParticipant,
          socket:socket.current,
          show_info,
          setShowInfo,
          show_chat,
          sala:sala.current,
          setShowChat,
          show_participants,
          setShowParticipants,
          username,
          admin_id:admin_id,
          playVideo,
          getAdminId: getAdminId,
        }}
      />
    </div>
  );
}
