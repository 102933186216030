import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./styles.module.css";
import SurveyCard from "./SurveyCard";
import MessageContainer from "./MessageContainer";
import TextareaAutosize from "react-textarea-autosize";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import imgtest from "assets/static/randompic.jpg";
import { Box } from "@mui/material";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import CallEndRoundedIcon from "@mui/icons-material/CallEndRounded";
import ScreenShareRoundedIcon from "@mui/icons-material/ScreenShareRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

const VCDemo = () => {
  return (
    <Box
      className={styles.demoContainer}
      sx={{
        display: {
          xs: "none",
          sm: "flex",
        },
      }}
    >
      <div className={styles.headerSection}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className={styles.vcContainer}>
        {/* video feed */}
        <div className={styles.videoContainer}>
          {/* <img
            src={imgtest}
            alt=""
            style={{
              width: "auto",
              height: "100%",
              objectFit: "cover",
              borderRadius: "1rem",
            }}
          /> */}

          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              backgroundImage: `url(${imgtest})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "1rem",
              display: "flex",
            }}
          />

          <div className={styles.vcActionsContainer}>
            <div>
              <VideocamRoundedIcon />
            </div>
            <div>
              <MicRoundedIcon />
            </div>
            <div>
              <CallEndRoundedIcon
                sx={{
                  color: "#fc3d3d",
                }}
              />
            </div>
            <div>
              <ScreenShareRoundedIcon />
            </div>
            <div>
              <SettingsRoundedIcon />
            </div>
          </div>
        </div>

        {/* chat */}
        <div className={styles.chatContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Chat</h3>
            <CloseRoundedIcon />
          </div>

          <div className={styles.chatMessagesContainer}>
            <MessageContainer
              text={"Bom dia pessoal, tudo bem?"}
              avatar={
                "https://media.licdn.com/dms/image/C4E03AQEbEmIqUMPuqg/profile-displayphoto-shrink_800_800/0/1586956466259?e=2147483647&v=beta&t=4-0fbc7BxYmhPfu-CDTo9drKo1NrFmxZJ_fLZsSsDc0"
              }
              username={"Allan"}
              datetime={"2021-10-10T10:00:00"}
            />
            <SurveyCard />
            <MessageContainer
              text={"enrico peaky blinders do mau"}
              image_url={"https://i.imgur.com/ahouM3Z.png"}
              avatar={"https://avatars.githubusercontent.com/u/44252635?v=4"}
              username={"meaw"}
              datetime={"2021-10-10T10:00:00"}
            />
            <MessageContainer
              text={"kkkkkkkkk"}
              datetime={"2021-10-10T10:00:00"}
              isMine={true}
            />
            <MessageContainer
              text={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donegestas, sed viverra. "
              }
              datetime={"2021-10-10T10:00:00"}
              isMine={true}
            />
          </div>

          {/* textarea */}
          <div className={styles.textareaContainer}>
            <TextareaAutosize
              type="text"
              placeholder="Digite sua mensagem aqui..."
              maxRows={4}
              style={{
                width: "100%",
                padding: "1rem",
                fontSize: "0.9rem",
                borderRadius: "1rem",
                border: "none",
                outline: "none",
                resize: "none",
                fontFamily: "inherit",
                backgroundColor: "var(--background-color1)",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "0.5rem",
                padding: "0.8rem",
                fontSize: "0.9rem",
                borderRadius: "1rem",
                backgroundColor: "rgba(var(--accent-color0), 0.4)",
                height: "max-content",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(var(--accent-color0), 0.5)",
                },
              }}
            >
              <SendRoundedIcon
                sx={{
                  color: "rgb(255, 255, 255)",
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default VCDemo;
