import { useEffect, useState } from "react";

// unidades
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Close, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Attention } from "components/Attention";
import { MdDelete } from "react-icons/md";
import { FormControlLabel, Radio, TextField } from '@material-ui/core';
import { RadioGroup } from "@mui/material";
import { FaPlus, FaTrash } from "react-icons/fa";
import { get, post } from "utilities/requests";
import { logout } from "components/actions/AppActions";


export function MeetForm(props) {

  const [name, setName] = useState('');
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);



  const [errorMessages, setMsgErro] = useState([]);
  const [guests, setGuests] = useState([]);

  const [date, setDate] = useState(moment());
  const [hour, setHour] = useState(moment());
  const [date_end, setDateEnd] = useState(moment());
  const [hour_end, setHourEnd] = useState(moment());
  const [local, setLocal] = useState('');
  const [link, setLink] = useState('');

  const dispatch = useDispatch();
  const user = useSelector(store => store.AppReducer.user);

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
      setMsgErro([])
      setLoadingSave(true);
      post(props.editMode?'meet/update': 'meet/store', JSON.stringify({
        name: name,
        date: date.format('YYYY-MM-DD') + ' ' + hour.format('HH:mm:ss'),
        date_end: date_end.format('YYYY-MM-DD') + ' ' + hour_end.format('HH:mm:ss'),
        local: local,
        link: link,
        send_email: sendEmail,
        guests: guests,
        meet_id:params.id
      })).then((resp) => {
        console.log(resp)
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          dispatch(logout())
          setLoadingSave(false);

          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);

          setLoadingSave(false);
          setMsgErro(errors)
          //   setTimeout(()=>{
          //     this.get_processo();
          //   });
        }
        else {
          setLoadingSave(false);

          history('/reunioes')
        }

      }).catch((e) => {
        setLoadingSave(false);
        console.log(e)
      })
  };

  useEffect(() => {
    if (props.editMode) {
      get('meet/get/' + params.id).then((resp) => {
        console.log(resp)

        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          setLoadingScreen(false);
          dispatch(logout())

          return;
        }
        else {
          setName(resp.meet.name);
          setLocal(resp.meet.local ?? '');
          setLink(resp.meet.link ?? '');
          setDate(moment(resp.meet.date));
          setHour(moment(resp.meet.date));
          if(resp.meet.date_end!=null){
            setDateEnd(moment(resp.meet.date_end));
            setHourEnd(moment(resp.meet.date_end));
          }
          setGuests(resp.meet.guests)
          setLoadingScreen(false);
        }

      }).catch((e) => {
        setLoadingScreen(false);
      })
      // dispatch(fetchId({ id: params.id, force: true }));
    }
    else {
      let guests_ = [];
      guests_.push({ is_host: true, name: user.name, email: user.email })
      setGuests(guests_)
      setLoadingScreen(false);

    }

    return () => {
      // dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Reunião
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma reunião do seu sistema"
                : "Cadastre uma reunião no seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loadingScreen === false && (
              <div>
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setMsgErro('')}
                      ></button>
                      {errorMessages.map((error) => (
                        <p style={{ marginBottom: 0 }}>{error}</p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Nome da reunião"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Local
                      </Form.Label>
                      <Input
                        placeholder="Local da reunião"
                        value={local}
                        onChange={(e) => setLocal(e.target.value)}
                      // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Link
                      </Form.Label>
                      <Input
                        placeholder="Link da reunião"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>



                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>
                          Data de início reunião <Attention />
                        </Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Data inválida"
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={date}
                          onChange={(date) => setDate(date)}
                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Horário de início da reunião <Attention />
                        </Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={hour}
                          onChange={(date) => setHour(date)}
                        />
                      </div>
                    </InputGroup>
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>
                          Data de encerramento <Attention />
                        </Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Data inválida"
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={date_end}
                          onChange={(date) => setDateEnd(date)}
                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Horário da encerramento da reunião <Attention />
                        </Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={hour_end}
                          onChange={(date) => setHourEnd(date)}
                        />
                      </div>
                    </InputGroup>
                  </MuiPickersUtilsProvider>



                  <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label >Convidados <Attention /> </Form.Label>
                      <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                          <table className="table table-striped">
                            <thead>
                              <tr>

                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Ações</th>
                              </tr>

                            </thead>
                            <tbody >

                              {guests.map((item, id) => (
                                <tr key={id}>
                                  <td>
                                    <Input
                                      placeholder={"Nome do convidado"}
                                      value={item.name}
                                      disabled={id==0}
                                      onChange={(e) => {
                                        let guests_ = [...guests];
                                        guests_[id].name = e.target.value;
                                        setGuests(guests_);
                                      }}
                                      required
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      placeholder={"E-mail do convidado"}
                                      value={item.email}
                                      disabled={id==0}

                                      onChange={(e) => {
                                        let guests_ = [...guests];
                                        guests_[id].email = e.target.value;
                                        setGuests(guests_);
                                      }}
                                      required
                                    />
                                  </td>
                                  <td >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                      {id!=0 ? <span
                                        onClick={() => {
                                          setGuests(guests.filter((item2, id2) => id2 != id));
                                        }}
                                        className="inline-flex align-center jcc bg-danger icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                        }}
                                        title="Deletar"
                                      >
                                        <MdDelete size={17} color="white" />
                                      </span> : <p style={{ textAlign: 'center', width: '100%' }}>-</p>}
                                    </div>

                                  </td>

                                </tr>
                              ))}

                              {guests.length == 0 && <tr >
                                <td colSpan="4">Nenhum convidado adicionado</td>
                              </tr>}



                            </tbody>
                          </table>
                        </div>
                      </div>


                      <div className='d-flex justify-content-end mt-3'>
                        <Button onClick={() => {
                          if (guests.length <= 1) {
                            return;
                          }
                          let guests_ = [...guests];
                          guests_.pop();
                          setGuests(guests_)
                        }} disabled={guests.length <= 1} variant="outline-danger" className="flex align-center jcc me-1"><FaTrash style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Remover participante</p></Button>
                        <Button onClick={() => {
                          let guests_ = [...guests];
                          guests_.push({ name: '', email: '' })
                          setGuests(guests_)
                        }} variant="outline-primary" className="flex align-center jcc"><FaPlus style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar participante</p></Button>

                      </div>

                    </div>
                  </InputGroup>

                  <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Enviar e-mail aos convidados? <Attention /></Form.Label>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group2"
                        value={sendEmail}
                        onChange={(e) => {
                        }}>

                        <FormControlLabel onClick={(e) => {
                          console.log(e.target.value);
                          setSendEmail(true)
                        }} control={<Radio checked={sendEmail == true} value={true} />} style={{ color: 'black' }} label={'Sim'} />
                        <FormControlLabel onClick={(e) => {
                          setSendEmail(false)
                        }} control={<Radio checked={sendEmail == false} value={false} />} style={{ color: 'black' }} label={'Não'} />
                      </RadioGroup>
                    </div>
                  </InputGroup>

                  {loadingSave === false && (
                    <div className="w100 inline-flex jcsb mt-3">
                     
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/reunioes")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} />{" "}
                        Voltar
                      </Button>
                    
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loadingSave}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loadingSave === true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen === true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
