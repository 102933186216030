import { Route, Routes } from "react-router-dom";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { useDispatch } from "react-redux";
import { login } from "components/actions/AppActions";

const RoutesContainer = () => {
  let user = localStorage.getItem('user');
  let token = localStorage.getItem('token');
  const dispatch = useDispatch();
  if(user && token){
    console.log('login')
    dispatch(login({user:JSON.parse(user),token}))
  }
  return (
    <PrivateRoutes />
    // <Routes>
    //   <Route path={"/dashboard"} element={<PrivateRoutes />} />
    //   {/* <Route path={"/*"} element={<GuestRoutes />} /> */}
    // </Routes>
  );
};

export default RoutesContainer;
