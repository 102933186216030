import Landing from "./Landing";
import MVPSection from "./MVPSection";
import Clientes from "./Clientes";
import FAQPage from "../FAQ";
import Contato from "../Contato";
// import Servicos from "./Servicos";
// import SobreNos from "./SobreNos";
// import Clientes from "./Clientes/Clientes";
// import Contato from "../Contato";

const HomePage = () => {

  return (
    <>
      <Landing />
      <MVPSection />
      <Clientes />
      <FAQPage />
      <Contato invert_bg_theme={true} />
      {/* <SobreNos /> */}
      {/* <Servicos {...{landing_data}} /> */}
      {/* <Depoimentos /> */}
      {/* clientes + depoimentos (mesma secao) sem o texto do depoimentos */}
    </>
  );
}

export default HomePage;