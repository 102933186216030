export default function ChatRoomMessageItem({
  user,
  message_text,
  has_media,
  media,
  isMine = false,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: isMine ? "flex-end" : "flex-start",
        alignItems: "flex-end",
        gap: "0.6rem",
      }}
    >
      {!isMine && (
        <img
          src={user.avatar_path}
          alt={`Avatar de ${user.name}`}
          style={{
            width: "3rem",
            height: "3rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          backgroundColor: !isMine
            ? "var(--background-color1)"
            : "rgb(var(--accent-color0), 0.2)",
          padding: "0.8rem 1rem",
          borderRadius: isMine ? "1rem 1rem 0 1rem" : "1rem 1rem 1rem 0",
          margin: isMine ? "0 0 0 1rem" : "0 1rem 0 0",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 2px 0px",
        }}
      >
        {!isMine && (
          <span
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            {user.name}
          </span>
        )}
        <div
          style={{
            fontSize: "1rem",
            lineHeight: "1.2rem",
            textAlign: isMine ? "right" : "left",
          }}
        >
          {message_text}
        </div>

        {has_media && (
          <div
            style={{
              marginTop: "0.6rem",
              width: "100%",
              height: "auto",
              borderRadius: "0.6rem",
              overflow: "hidden",
            }}
          >
            {media.type === "image" && (
              <img
                src={media.path}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
