export function Header(props) {
  return (
    <div>
      <h4
        className="mt-0 header-title"
        style={{ fontSize: "1.5rem", color: "black" }}
      >
        Lista de {props.title}
      </h4>
      <p style={{ fontSize: "1rem", color: "black" }}>
        {props.pronoun && props.pronoun === "a"
          ? " Aqui são listadas todas as suas "
          : " Aqui são listados todos os seus "}
        {props.title.toLowerCase()}
      </p>
      <br />
    </div>
  );
}
