import { ArrowLineRight } from "@phosphor-icons/react";
import ChatRoomMessageItem from "./MessageContainer";
import { Box, Tooltip } from "@mui/material";
import TextareaAutosize from "react-textarea-autosize";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

export default function ChatRoom({ show_chat, setShowChat }) {
  const messages = [
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/200",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/201",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/202",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/203",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/204",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      has_media: true,
      media: {
        type: "image",
        path: "https://picsum.photos/480/270",
      },
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/205",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/206",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/207",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
    {
      user: {
        name: "Robertoso da Silva",
        avatar_path: "https://picsum.photos/208",
      },
      message_text: "Bom dia pessoal, tudo bem?",
      datetime: "2021-08-15T20:00:00.000Z",
    },
  ];
  return (
    <div
      className="Transition-1"
      style={{
        height: "100%",
        width: "30rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color0)",
        borderRadius: "1rem 0 0 1rem",
        padding: "1rem",

        position: "absolute",
        top: "0",
        right: show_chat ? "0" : "-100vw",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          Chat
        </span>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Esconder chat
            </span>
          }
          placement="bottom"
          arrow
          onClick={() => setShowChat(false)}
        >
          <ArrowLineRight size={24} />
        </Tooltip>
      </div>

      {/* chat container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {messages.map((message, index) => (
          <ChatRoomMessageItem
            key={index}
            {...{
              ...message,
              isMine: index % 2 === 0,
            }}
          />
        ))}
      </div>

      {/* input */}
      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0.8rem",
            borderRadius: "1rem",

            backgroundColor: "var(--background-color2)",
            height: "max-content",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(var(--accent-color1), 0.3)",
            },
          }}
        >
          <AttachFileOutlinedIcon
            sx={{
              color: "rgb(255, 255, 255)",
            }}
          />
        </Box>
        <TextareaAutosize
          type="text"
          placeholder="Digite sua mensagem aqui..."
          maxRows={4}
          style={{
            width: "100%",
            padding: "1rem",
            fontSize: "0.9rem",
            borderRadius: "1rem",
            border: "none",
            outline: "none",
            resize: "none",
            fontFamily: "inherit",
            backgroundColor: "var(--background-color1)",
            color: "var(--text-color0)",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0.8rem",
            borderRadius: "1rem",
            backgroundColor: "rgba(var(--accent-color0), 0.4)",
            height: "max-content",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(var(--accent-color0), 0.5)",
            },
          }}
        >
          <SendRoundedIcon
            sx={{
              color: "rgb(255, 255, 255)",
            }}
          />
        </Box>
      </div>
    </div>
  );
}
