import CustomInput from "components/CustomInput";
import form_styles from "components/SpecificStyles/form.module.css";
import PersonIcon from "@mui/icons-material/Person";
import { useEffect, useState } from "react";
import ControlButton from "components/ControlButtom";
import {
  Microphone,
  MicrophoneSlash,
  VideoCamera,
  VideoCameraSlash,
} from "@phosphor-icons/react";

export default function UserInfoForm({
  roomName,
  handleRoomName,
  handleConnect,

  username,
  handleUsername,

  // media control
  handleStartMedia,
  localVideo,

  // streaming control
  localStream,
  enableVideoStream,
  handleEnableVideoStream,
  enableAudioStream,
  handleEnableAudioStream,
}) {
  useEffect(() => {
    handleStartMedia();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        gap: "3rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "var(--background-color1)",
            borderRadius: "0.6rem",
            overflow: "hidden",
            position: "relative",
            height: "20rem",
            justifyContent: "center",
            aspectRatio: "16/9",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "20rem",
              backgroundColor: "black",
              display: enableVideoStream ? "none" : "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.4rem",
              padding: "0 6rem",
              color: "white",
              position: "absolute",
            }}
          >
            Câmera desligada
          </div>
          <video
            ref={localVideo}
            autoPlay
            style={{
              width: "100%",
              height: "20rem",
              objectFit: "contain",
            }}
          ></video>
        </div>

        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <ControlButton
            tooltip={enableVideoStream ? "Desligar câmera" : "Ligar câmera"}
            icon={
              enableVideoStream ? (
                <VideoCamera weight="fill" />
              ) : (
                <VideoCameraSlash />
              )
            }
            onClick={() =>
              enableVideoStream
                ? handleEnableVideoStream(localStream.current, false)
                : handleEnableVideoStream(localStream.current, true)
            }
          />
          <ControlButton
            tooltip={
              enableAudioStream ? "Desligar microfone" : "Ligar microfone"
            }
            icon={
              enableAudioStream ? (
                <Microphone weight="fill" />
              ) : (
                <MicrophoneSlash />
              )
            }
            onClick={() =>
              enableAudioStream
                ? handleEnableAudioStream(localStream.current, false)
                : handleEnableAudioStream(localStream.current, true)
            }
          />
        </div>
      </div>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
        className={form_styles.customFormStyle}
        onSubmit={(e) => {
          e.preventDefault();
          handleConnect();
        }}
      >
        <CustomInput
          placeholder="Nome"
          icon={PersonIcon}
          value={username}
          onChangeTarget={handleUsername}
          helperText="Insira seu nome civil ou social."
          required
        />
        <CustomInput
          placeholder="ID da sala"
          icon={PersonIcon}
          value={roomName}
          onChangeTarget={handleRoomName}
          helperText="Insira o ID da sala que deseja entrar."
          required
        />

        <input type="submit" />
      </form>
    </div>
  );
}
